import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Col, Container, Row } from 'react-bootstrap'
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
export default function Location() {
    
      const [minHeights, setMinHeight] = useState('460px')
      const [setting, setSetting] = useState([])
    useEffect(() => {
        async function getSettings() {
            try {
                const settings = await axios.post("https://backend.layadevelopers.com/api/generalSetting")
                
                setSetting(settings.data)
            } catch (error) {
                console.log(error)
            }
        }
        getSettings()
    }, [setSetting])
  return (
    <section className='my-5 py-5' id='location'>
    <Container>
        <Row>
            <Col>
                <Row className='g-0'>
                    <Col xs={12} md={6} lg={4} className="bgMain ">
                        <div className='contDetail p-5 my-auto'>
                            <div className='d-flex justify-content-start text-white mb-5'>
                                <div className='' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1500"><h3><FaPhoneAlt/></h3></div>
                                <div className=' ps-5'>
                                   <div className='mb-3' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1700"><h3>Call Us</h3></div>
                                   <div className='' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1900"><a href={"tel:"+setting.phone} className='text-sec text-decoration-none'>
                                   {setting.phone}</a></div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-start text-white mb-5'>
                                <div className='' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1500"><h3><FaEnvelope/></h3></div>
                                <div className=' ps-5'>
                                   <div className='mb-3' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1700"><h3>Email Us</h3></div>
                                   <div className='' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1900"><a href={"mailto:"+setting.mail} className='
                                   text-sec text-decoration-none'>{setting.mail}</a></div>
                                   
                                </div>
                            </div>
                            <div className='d-flex justify-content-start text-white'>
                                <div className='' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1500"><h3><FaMapMarkerAlt/></h3></div>
                                <div className=' ps-5'>
                                   <div className='mb-3' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1700"><h3>Office</h3></div>
                                   <div className='' data-aos="fade-right" data-aos-offset="100" data-aos-duration="1900"><a href={'https://maps.google.com/maps?q='+setting.address_latitude+','+setting.address_longitude} className='text-sec text-decoration-none'>
                                   {setting.address}
                                    </a></div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={8} className="my-auto">
                     <div data-aos="fade-right" data-aos-duration="2000" data-aos-offset="100">
                       
                    <iframe src={'https://maps.google.com/maps?q='+setting.address_latitude+','+setting.address_longitude+'&z=17&ie=UTF8&iwloc=&output=embed'} width="100%" height="100%" style={{ border : 0,  minHeight: minHeights }} allowFullScreen="" loading="lazy" title='location' referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>   
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
</section>
  )
}
