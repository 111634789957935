import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Col, Container, Row } from 'react-bootstrap'
import brand1 from "../../Assets/Images/brandAbout.webp"
export default function About() {
    const [about, setAbout] = useState([])
    useEffect(() => {
        async function getAboutDetails() {
            try {
                const aboutUs = await axios.post("https://backend.layadevelopers.com/api/about"
              )
              setAbout(aboutUs.data)
            } catch (error) {
                console.log(error)
            }
        }
        getAboutDetails()
     
    }, [])
    const first = about[0];
  return (
    <section className='py-5' id='about'>
        <div className=' aboutSec'>
            <div className="containerbackground">
                <h1>LAYA</h1>
            </div>
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <div className='heading-all text-uppercase text-start mb-4 mt-5'  data-aos="fade-right"  data-aos-offset="300" data-aos-duration="1500">
                                    <h2>{first?.heading}</h2>
                                </div>
                                <div  data-aos="fade-right"  data-aos-offset="300" data-aos-duration="2000">
                                    <div className='fw-bold mb-3' dangerouslySetInnerHTML={{ __html: first?.short_desc }}></div>
                                    <div className='text-para' dangerouslySetInnerHTML={{ __html: first?.description }}></div>
                                    
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={6} className="my-auto">
                                <div className='p-relative  mx-0 mx-md-3 mx-lg-5'>
                                    <div className='boxPrimary'  data-aos="fade-right"  data-aos-offset="300" data-aos-duration="1500"></div>
                                    <img src={brand1}   data-aos="fade-right"  data-aos-offset="300" data-aos-duration="2000" className="img-fluid p-4" alt="Laya Developers" />
                                </div>
                            </Col>
                        </Row>
                       
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
  )
}

