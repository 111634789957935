import React, { Component } from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import banner1 from "../../Assets/Images/banner/careerBg.webp"

export default class Banner extends Component {
    render() {
        return (
            <section>
                <Container fluid className='px-0'>
                    <Row className='g-0'>
                        <Col xs={12} md={12} lg={12}>
                            <Carousel className='bannerCarousel'>
                                <Carousel.Item  interval={3000}>
                                    <img className="d-block w-100" src={banner1} alt="First slide" />
                                    <Container className='centered'>
                                        <Row className='justify-content-center'>
                                            <Col xs={12} md={12} lg={6}>
                                                <div data-aos="fade-up" data-aos-duration="1500">
                                                    <h1 className='main-heading'>Become An Agent</h1>
                                                </div>
                                                
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Carousel.Caption>
                                        <div className='scrollDiv'>
                                            <a href='#join' className='text-decoration-none text-white'>scroll 
                                             </a>
                                        </div>
                                        <div className='scrollDiv'>
                                            <a href='#join' className='text-decoration-none text-white'>
                                                <span className='line' ></span>
                                                <div className='arrowSpan mouse-croll'>
                                                    <div className="down-arrow-1"></div>
                                                    <div className="down-arrow-2"></div>
                                                </div>
                                            </a>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
