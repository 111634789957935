import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import layaresidence from '../../Assets/Images/layaresidence.webp'
import layaheight from '../../Assets/Images/layaheight.webp'
import layamansion from '../../Assets/Images/layamansion.webp'
import { Link } from 'react-router-dom'

export default class Project extends Component {
   

    render() {
        return (
            <section>
                <Container fluid className='px-0'>
                    <Row className='g-0'>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-0'>
                                <Col xs={12} md={6} lg={4}>
                                    <div className='imgContPro' data-aos="fade-up" data-aos-duration="1000">
                                        <img src={layaresidence} className="img-fluid" alt="Laya Developers" />
                                        <div className='proDetails p-4'>
                                            <div className='proName'>
                                                <h4 className='text-uppercase text-white'>Laya Residences</h4>
                                            </div>
                                            <div className='proDesc border-top'>
                                                <div className='text-para my-3 text-sec'>
                                                    <p>
                                                    Welcome to a living experience that is distinguished by its own style, where you can lead a life of style and elegance.
                                                    </p>
                                                </div>
                                                <div>
                                                <Link to={"/laya-residences"} className='text-decoration-none'><p className='text-sec'>Read More &nbsp;<span>&#10230;</span>    
                                                     </p></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <div className='imgContPro' data-aos="fade-up" data-aos-duration="1500">
                                        <img src={layamansion} className="img-fluid" alt="Laya Developers" />
                                        <div className='proDetails p-4'>
                                            <div className='proName'>
                                                <h4 className='text-uppercase text-white'>Laya Mansion</h4>
                                            </div>
                                            <div className='proDesc border-top'>
                                                <div className='text-para my-3 text-sec'>
                                                    <p>
                                                    Our signature LAYA Mansion are made with breathtaking luxury designs and which create an ornamentally stylized aura spread over the full complex. 
                                                    </p>
                                                </div>
                                                <div>
                                                <Link to={"/laya-mansion"} className='text-decoration-none'><p className='text-sec'>Read More &nbsp;<span>&#10230;</span>    
                                                     </p></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <div className='imgContPro' data-aos="fade-up" data-aos-duration="2000">
                                        <img src={layaheight} className="img-fluid" alt="Laya Developers" />
                                        <div className='proDetails p-4'>
                                            <div className='proName'>
                                                <h4 className='text-uppercase text-white'>Laya Heights</h4>
                                            </div>
                                            <div className='proDesc border-top'>
                                                <div className='text-para my-3 text-sec'>
                                                    <p>
                                                    Awaken your senses with every breath, as you redefine your premium lifestyle in Dubai.
                                                    </p>
                                                </div>
                                                <div>
                                                <Link to={"/laya-heights"} className='text-decoration-none'><p className='text-sec'>Read More &nbsp;<span>&#10230;</span>    
                                                     </p></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
