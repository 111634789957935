import React from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import banner1 from "../../Assets/Images/courtyard.webp"
import { FaMapMarkerAlt } from 'react-icons/fa'

export default function Banner() {

    return (
        <section>
            <Container fluid className='px-0'>
                <Row className='g-0'>
                    <Col xs={12} md={12} lg={12}>
                    <Carousel className='bannerCarousel'>
                            <Carousel.Item interval={3000}>
                                <img className="d-block w-100" src={banner1} alt="First slide" />
                                <Container className='centered'>
                                    <Row className='justify-content-center text-center text-white'>
                                        <Col xs={12} md={12} lg={12}>
                                            <div data-aos="fade-up" data-aos-duration="1500">
                                                <h1 className='main-heading mb-0'>LAYA COURTYARD</h1>
                                            </div>
                                            <div data-aos="fade-up" data-aos-duration="1700">
                                                <h5><FaMapMarkerAlt /> DUBAI STUDIO CITY</h5>
                                            </div>
                                            
                                        </Col>
                                        <Col xs={12} md={12} lg={4}>
                                            
                                            <div className='projDet' data-aos="fade-up" data-aos-duration="2100">
                                                <div className='text-black'>
                                                    <h5 className='mb-0'>COMING SOON</h5>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </Container>
                                
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
