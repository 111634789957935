import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/BecomeAgent/Banner'
import Join from '../Components/BecomeAgent/Join'

export default function BecomeAgent() {
 
  return (
    <main>
      <HelmetProvider>
      <Helmet> <title>Become An Agent | Laya Developers</title></Helmet>
      </HelmetProvider>
      <Banner/>
      <Join />
    </main>
  )
}
