import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Col, Container, Row } from 'react-bootstrap'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { Autoplay } from "swiper";
export default function Logos() {
    const [logo, setLogo] = useState([])
    useEffect(() => {
        async function getLogo() {
            try {
                const logo = await axios.post("https://backend.layadevelopers.com/api/developers"
                )
                setLogo(logo.data)
            } catch (error) {
                console.log(error)
            }
        }
        getLogo()
    }, [])
    return (
        <section className='pb-5'>

            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <div className='heading-all text-uppercase text-center mb-5' data-aos="fade-up" data-aos-duration="1500">
                                    <h2>HERE ARE OUR SOME OF OUR PARTNERS</h2>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <Swiper
                                    slidesPerView={2}
                                    spaceBetween={10}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            spaceBetween: 40,
                                        },
                                        1024: {
                                            slidesPerView: 5,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    modules={[Autoplay]}
                                    className="mySwiperLogo"
                                >
                                    {
                                        logo.map((logos, i) => {
                                            return (
                                                <SwiperSlide key={logos.id}>
                                                    <div><img src={'https://backend.layadevelopers.com/'+logos.logo} className="img-fluid" alt="Laya Developers" /></div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>

        </section>
    )
}

