import React, { useEffect, useState } from 'react'
import axios from "axios";
import moment from "moment";
import { Col, Container, Row, Card } from 'react-bootstrap'
import { FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Latest() {
    const [blogs, setBlog] = useState([])
    const [news, setNews] = useState([])
    const [popular, setPopular] = useState([])
    useEffect(() => {
        async function getLatestBlog() {
            try {
                const blogs = await axios.post("https://backend.layadevelopers.com/api/blogs"
              )
              setBlog(blogs.data)
            } catch (error) {
                console.log(error)
            }
        }
        getLatestBlog()
        async function getPopular() {
            try {
                const popular = await axios.post("https://backend.layadevelopers.com/api/popular")
                setPopular(popular.data)
            } catch (error) {
                console.log(error)
            }
        }
        getPopular()
        async function getLatestNews() {
            try {
                const news = await axios.post("https://backend.layadevelopers.com/api/news")
                setNews(news.data)
            } catch (error) {
                console.log(error)
            }
        }
        getLatestNews()
    }, [])
    
    
    return (
        <section className='mb-5'>
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={8}>
                        <Row className='g-3'>
                            <Col xs={12} md={12} lg={12}>
                                <div className="heading-all text-uppercase text-main borNewBott mb-3" data-aos="fade-right" data-aos-offset="300" data-aos-duration="1500">
                                    <h4>LATEST NEWS</h4>
                                </div>
                            </Col>

                            {
                                news.map((news, i) => {
                                    return (
                                        <Col xs={12} md={12} lg={12}  key={news.id}>
                                            <Card className='bg-transparent border-0'>

                                                <Row className=''>
                                                    <Col xs={4} md={4} lg={5} className="my-auto">
                                                        <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'><Card.Img src={'https://backend.layadevelopers.com/' + news.image} data-aos="fade-right" data-aos-offset="300" data-aos-duration="2200" className="img-fluid rounded-0" /></Link>
                                                    </Col>
                                                    <Col xs={8} md={8} lg={7} className="my-auto">
                                                        <Card.Body data-aos="fade-right" className='blogCard' data-aos-offset="300" data-aos-duration="2200">
                                                            <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'><Card.Title className='text-main'><h5>{news.title.substring(0, 70)}
                                                            </h5>
                                                            </Card.Title></Link>
                                                            <div className='d-flex justify-content-start mb-2 fs-14'>
                                                                <div className='me-2 my-auto text-sec'><FaClock /></div>
                                                                <div className='my-auto'>{moment(news.publish_date).format("MMMM D, YYYY")}{" "}</div>
                                                            </div>
                                                            <Card.Text className='fs-14'>
                                                                <div> {news.description.replace(/(<([^>]+)>)/gi, " ").substring(0, 80)}
                                                                </div>
                                                            </Card.Text>
                                                            <div>
                                                                <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'> <button className='btn btn-main'>Read More</button></Link>
                                                            </div>
                                                        </Card.Body>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                            <Col xs={12} md={12} lg={12}>
                                <div data-aos="fade-right" data-aos-offset="300" data-aos-duration="2000">
                                <Link to="/news" className='text-dark text-decoration-none'> <button className='btn btn-sec'>Load More</button></Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className='g-3 my-5'>
                            <Col xs={12} md={12} lg={12}>
                                <div className="heading-all text-uppercase text-main borNewBott mb-3" data-aos="fade-right" data-aos-offset="300" data-aos-duration="2100">
                                    <h4>LATEST BLOGS</h4>
                                </div>
                            </Col>
                            {
                                blogs.map((blog, i) => {
                                    return (
                                        <Col xs={12} md={12} lg={12} key={blog.id}>
                                            <Card className='bg-transparent border-0'>

                                                <Row className=''>
                                                    <Col xs={4} md={4} lg={5} className="my-auto">
                                                        <Link to={`/blog/${blog.slug}`} className='text-dark text-decoration-none'><Card.Img src={'https://backend.layadevelopers.com/' + blog.image} data-aos="fade-right" data-aos-offset="300" data-aos-duration="2200" className="img-fluid rounded-0" /></Link>
                                                    </Col>
                                                    <Col xs={8} md={8} lg={7} className="my-auto">
                                                        <Card.Body data-aos="fade-right"  className='blogCard' data-aos-offset="300" data-aos-duration="2200">
                                                            <Link to={`/blog/${blog.slug}`} className='text-dark text-decoration-none'><Card.Title className='text-main'><h5>{blog.title.substring(0, 70)}
                                                            </h5>
                                                            </Card.Title></Link>
                                                            <div className='d-flex justify-content-start mb-2 fs-14'>
                                                                <div className='me-2 my-auto text-sec'><FaClock /></div>
                                                                <div className='my-auto'>{moment(blog.publish_date).format("MMMM D, YYYY")}{" "}</div>
                                                            </div>
                                                            <Card.Text className='fs-14'>
                                                                <div> {blog.description.replace(/(<([^>]+)>)/gi, " ").substring(0, 80)}
                                                                </div>
                                                            </Card.Text>
                                                            <div>
                                                                <Link to={`/blog/${blog.slug}`} className='text-dark text-decoration-none'> <button className='btn btn-main'>Read More</button></Link>
                                                            </div>
                                                        </Card.Body>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }

                            <Col xs={12} md={12} lg={12}>
                                <div data-aos="fade-right" data-aos-offset="300" data-aos-duration="2500">
                                <Link to="/blogs" className='text-dark text-decoration-none'> <button className='btn btn-sec'>Load More</button></Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <Row className='g-3'>
                            <Col xs={12} md={12} lg={12}>
                                <div className="bgMain p-2 text-uppercase text-white mb-3" data-aos="fade-right" data-aos-offset="300" data-aos-duration="1500">
                                    <h6 className='mb-0'>MOST POPULAR</h6>
                                </div>
                            </Col>
                            {
                                popular.map((popular, i) => {
                                    return (
                                        <Col xs={12} md={12} lg={12}  key={popular.id}>
                                <Card className='bg-transparent border-0'>
                                    <Row className='g-0'>
                                        <Col xs={4} md={4} lg={5} className="my-auto">
                                            <Link to={`/blog/${popular.slug}`} className='text-dark text-decoration-none'><Card.Img data-aos="fade-right" data-aos-offset="300" data-aos-duration="1600" src={'https://backend.layadevelopers.com/' + popular.image} className="img-fluid rounded-0" /></Link>
                                        </Col>
                                        <Col xs={8} md={8} lg={7} className="my-auto">
                                            <Card.Body className='p-2' data-aos="fade-right" data-aos-offset="300" data-aos-duration="1600">
                                                <Link to={`/blog/${popular.slug}`} className='text-dark text-decoration-none'><Card.Title className='text-main'><h5 className=' fs-14'>{popular.title.substring(0, 40)}
                                                </h5>
                                                </Card.Title></Link>
                                                <div className='d-flex justify-content-start fs-14'>
                                                    <div className='me-2 my-auto text-sec'><FaClock /></div>
                                                    <div className='my-auto'>{moment(popular.publish_date).format("MMMM D, YYYY")}{" "}</div>
                                                </div>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                                    )
                                })
                            }
                            
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
