import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutHome from '../../Assets/Images/aboutHome.webp'
import home1 from '../../Assets/Images/home1.png'
import home2 from '../../Assets/Images/home2.gif'
import { Link } from 'react-router-dom';

export default function ABoutNew() {
   
    const [style, setStyle] = useState({ display: 'none' });
    const [otherStyle, setOtherStyle] = useState({ display: 'block' });
    const [styleMain, setStyleMain] = useState({ display: 'flex' });
    const [styleNew, setStyleNew] = useState({ display: 'none' });
    const callTimer = (e) => {
        e.preventDefault();
        
        setTimeout(() =>
            setStyleMain({ display: 'none' })
            , 1000);
            setTimeout(() =>
            setStyleNew({ display: 'block' })
            , 1000);
           
    }
    return (
        <section className='py-5' id='about'>
            <div className=' aboutSec'>
                <div className="containerbackground">
                    <h1>LAYA</h1>
                </div>
                <Container>

                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Col xs={12} md={12} lg={3}>
                                    <div className='heading-all text-uppercase text-center' data-aos="fade-right" data-aos-duration="1000">
                                        <h2>About Us</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={5}>
                                    <div className=' px-lg-5 px-md-1 px-0'>


                                        <div className='text-para mb-5' data-aos="fade-right" data-aos-duration="1500">
                                            <p>
                                                LAYA developers is a real estate brand providing high-end finishing and high-quality apartments at
                                                an
                                                affordable price and giving value back to the customers in every way possible. From having
                                                developed
                                                studios, and 1 & 2-bedroom apartments that saw a quick sellout after their launch, we thrive to
                                                expand
                                                our communities and developments. As one of the best real estate brands in Dubai, we choose the
                                                most
                                                renowned places in the city so that our communities may flourish.
                                            </p>
                                        </div>
                                        <div  data-aos="fade-right" data-aos-duration="1700">
                                        <Link to={"/our-brand"} className='text-decoration-none text-main'> <p className='text-decoration-none text-main'> KNOW MORE ABOUT US &nbsp;<span>&#10230;</span></p></Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <div  data-aos="fade-right" data-aos-duration="2000">
                                        <img src={aboutHome} className="img-fluid" alt="Laya Developers" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='bgAbout'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={7} lg={8}>
                            <div className='heading-all text-uppercase text-center'  data-aos="fade-up" data-aos-duration="2000">
                                <h2>Open the door to discover the inspiration behind LAYA</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <div className='text-center d-flex justify-content-center'  data-aos="fade-up" data-aos-duration="2200">
                                <div className='imageHoverCont' style={styleMain}>
                                    <div className='imgMainHover my-auto' style={otherStyle} onClick={e => {
                                        setOtherStyle({ display: 'none' });
                                        setStyle({ display: 'block' });
                                        setTimeout(() =>
                                            callTimer(e)
                                            , 1000);


                                    }}
                                    ><img src={home1} className="img-fluid roundedCircle pointerCursor" alt="Laya 
                                         Developers" /></div>
                                    <div className='imgHoverAfter' style={style}>
                                        {/* <div id="wave">
                                            <span className="dot"></span>
                                            <span className="dot"></span>
                                            <span className="dot"></span>
                                        </div> */}
                                        <img src={home2} className="img-fluid roundedCircle" alt="Laya 
                                         Developers" />
                                    </div>

                                </div>
                                <div className='bgMain p-3 rounded50 text-white' style={styleNew}>
                                    <p className='mb-0 '>Laya is a Sanskrit term denoting a "residence", "place of rest", or "house" according to Vāstuśāstra literature.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}
