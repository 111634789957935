import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/Contact/Banner'
import Location from '../Components/Contact/Location'

export default function Contact() {
 
  return (
    <main>
      <HelmetProvider>
      <Helmet> <title>Contact Us | Laya Developers</title></Helmet>
      </HelmetProvider>
      <Banner />
      <Location />
    </main>
  )
}
