import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import visa1 from '../../Assets/Images/visa1.png';
import visa from '../../Assets/Images/visa.png';
import master from '../../Assets/Images/master.png';
import master1 from '../../Assets/Images/master2.png';
import magnati from '../../Assets/Images/magnati.png';
export default class About extends Component {
    render() {
        return (
            <section className='py-5' id='home'>
                <Container>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <h1>Payment Page Terms and Conditions</h1>
                                    <p>The Online Payment Terms and conditions constitute a contract between Laya Developers
                                        LLC (“Laya”) and the User who uses the Online Services provided for any payment made
                                        to Laya.</p>
                                    <p>Kindly read these terms and conditions carefully. By authorizing a payment to Laya
                                        through the online payment service ("the service"), it would be treated as a deemed
                                        acceptance to these terms and conditions. Laya reserves all the rights to amend these
                                        terms and conditions at any time without giving prior notice. It is the responsibility of the
                                        User to have read the terms and conditions before using the Service.</p>
                                    <h2>1. Terms</h2>
                                    <p>These Terms form part of Laya’s website terms and conditions of use, which govern your
                                        access to and usage of the Laya website. By agreeing to comply with the Terms you also
                                        agree to comply with Laya’s website terms and conditions of use. If there is a conflict
                                        between Laya’s website terms and conditions of use and these Terms, these Terms shall
                                        prevail.</p>
                                    <h2>2. Method of Payment, Card Types accepted and Currency:</h2>
                                    <ul>
                                        <li>We accept payments online using Visa and MasterCard credit/debit card in AED (or
                                            any other agreed currency).</li>
                                        <li>We are not directly involved in the online payment and using a third party service
                                            provider for the same.</li>
                                        <li>Once a payment has been made, it cannot be cancelled. We do not accept any
                                            responsibility for refusal or reversal of payments, which shall be a matter between
                                            you and your credit/debit card issuer.</li>
                                        <li>If any transaction is found to be incorrect, unauthorized or fraudulent, payment may
                                            be reversed and payments shall remain due.</li>
                                    </ul>
                                    <div className='d-flex justify-content-start py-3'>
                                        <div className='d-flex justify-content-start'>
                                            <div className='footSocLogo pe-3'><img src={visa} className="img-fluid" width="100" alt="logo" /></div>
                                            <div className='footSocLogo pe-3'><img src={visa1} className="img-fluid" width="100" alt="logo" /></div>
                                            <div className='footSocLogo pe-3'><img src={master} className="img-fluid" width="100" alt="logo" /></div>
                                            <div className='footSocLogo pe-3'><img src={master1} className="img-fluid" width="100" alt="logo" /></div>
                                            <div className='footSocLogo'><img src={magnati} className="img-fluid" width="100" alt="logo" /></div>
                                        </div>
                                    </div>
                                    <h2>3. Refund/Return Policy</h2>
                                    <ul>
                                        <li>Refunds, if applicable, at the discretion of Laya Developers, will be done only
                                            through the Original Mode of Payment</li>
                                        <li>Refunds of any payments (if any) made through the Website are subject to the
                                            terms and conditions of the Booking Confirmation or Sale and Purchase Agreement.</li>
                                    </ul>
                                    <h2>4. Reservation and Booking</h2>
                                    <ul>
                                        <li>The reservation of the unit shall be complete once the minimum amount of booking,
                                            which shall be intimated on case-to-case basis or project to project, is paid along with
                                            applicable registration fees and the same is received to Laya Developers within 7 days
                                            from the date of making a payment as an expression of interest or booking fee.</li>
                                        <li>In the event of cancellation due to any event including, non fulfilment of the payment or
                                            signing of the required sale and purchase agreement then the expression of interest or
                                            Booking fee shall be forfeited and the Booking Confirmation and any receipts issued
                                            towards the Unit shall be treated as invalid and we reserve our rights over the Unit.</li>
                                        <li>The booking fee is non-transferable to any third party and the Purchaser is under a
                                            continue obligation to abide by all the applicable laws and regulations.</li>
                                    </ul>
                                    <h2>5. Fees</h2>
                                    <ul>
                                        <li>Payments made by credit card attract a merchant service fee which is dependent on the
                                            amount being transacted and the currency.</li>
                                        <li>International cardholders using the Service may incur their own bank’s international
                                            service fees, in addition to the merchant service fee.</li>
                                    </ul>
                                    <h2>6. Governing Law and Jurisdiction</h2>
                                    <ul>
                                        <li>Any purchase, dispute or claim arising out of or in connection with this website shall
                                            be governed and construed in accordance with the laws of UAE.</li>
                                    </ul>
                                    <h2>7. Delivery/Shipping/Services Policy:</h2>
                                    <ul>
                                        <li>(Website) will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE</li>
                                    </ul>
                                    <h2>8. Cancellation & Replacement Policy:</h2>
                                    <ul>
                                        <li>Each Merchant/service provider has its own refund and cancellation policy, as a result of which we adhere to the same. Also, refunds will be done only through the Original Mode of Payment.</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
