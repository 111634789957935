import React from 'react'
import About from '../Components/Heights/About'
import Amenities from '../Components/Heights/Amenities'
import Banner from '../Components/Heights/Banner'
import Bedrooms from '../Components/Heights/Bedrooms'
import Location from '../Components/Heights/Location'
import Gallery from '../Components/Heights/Gallery'
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default function Heights() {
  
  return (
    <main>
      <HelmetProvider><Helmet><title>Laya Heights | Laya Developers</title></Helmet></HelmetProvider>
        <Banner />
        <About />
        <Bedrooms />
        <Amenities />
        <Location />
        <Gallery />
    </main>
  )
}
