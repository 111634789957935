import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import journey1 from "../../Assets/Images/journey1.webp"
import journey2 from "../../Assets/Images/journey2.webp"
import journey3 from "../../Assets/Images/journey3.webp"
import journey4 from "../../Assets/Images/journey4.webp"
import journey5 from "../../Assets/Images/property/courtyard.webp"
export default class Journey extends Component {
    render() {
        return (
            <section id='journey'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='justify-content-center mb-5'>
                                <Col xs={12} md={7} lg={7}>
                                    <div className='heading-all text-uppercase text-center mb-4 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <h2>OUR JOURNEY</h2>
                                    </div>
                                    <div data-aos="fade-up" data-aos-duration="1700">
                                        <p className='text-para text-center'>
                                            Our trajectory has been incredibly rewarding as we made real estate a seamless and pleasant
                                            process for our loyal clientele. Although we are proud of what we have achieved thus far, our
                                            journey has only just begun!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row className=''>
                                <Col xs={12} md={6}  lg={4} className="my-auto">
                                    <div data-aos="fade-up" data-aos-duration="1800"><img src={journey1} className="img-fluid" alt="Laya Developers" /></div>
                                </Col>
                                <Col xs={12} md={1}  lg={1} className="my-auto d-md-none d-lg-block"></Col>
                                <Col xs={12} md={6} lg={4} className='my-auto p-relative'>
                                    <span className='lineJourney' ></span>
                                    <div className='text-start paddJourn'  data-aos="fade-up" data-aos-duration="2000">
                                        <div className='heading-all text-uppercase mb-2'>
                                            <h2>2014 – INCEPTION</h2>
                                        </div>
                                        <div>
                                            <p className='text-para mb-0'>
                                            The vision came to life and Laya was brought about as the one-stop real estate solution for investors and home-buyers in Dubai, with the construction of LAYA Residences.

                                            </p>
                                        </div>
                                    </div>
                                    
                                </Col>
                                <Col xs={12}  lg={3} className="my-auto d-md-none d-lg-block"></Col>
                                </Row>
                                <Row className=''>
                                <Col xs={12}  lg={3} className="my-auto  d-md-none d-lg-block"></Col>
                                <Col xs={12} md={6} lg={4} className='my-auto'>
                                    
                                    <div className='text-end paddJourn' data-aos="fade-up" data-aos-duration="2200">
                                        <div className='heading-all text-uppercase mb-2'>
                                            <h2>2016 – LAUNCHED LAYA RESIDENCES</h2>
                                        </div>
                                        <div>
                                            <p className='text-para mb-0'>
                                            Laya Residences evokes Dubai's magnificent architecture and comfort that helps you live in luxury. The property is already 100% sold out and successfully handed over to the residents.
                                            </p>
                                        </div>
                                    </div>
                                    
                                </Col>
                                <Col xs={12}  lg={1} className="my-auto d-md-none d-lg-block"></Col>
                                <Col xs={12} md={6} lg={4} className="my-auto">
                                    <div data-aos="fade-up" data-aos-duration="2400"><img src={journey2} className="img-fluid" alt="Laya Developers" /></div>
                                </Col>
                                </Row>
                                <Row className='rowRev'>
                                <Col xs={12} md={6} lg={4} className="my-auto">
                                    <div data-aos="fade-up" data-aos-duration="2600"><img src={journey3} className="img-fluid" alt="Laya Developers" /></div>
                                </Col>
                                <Col xs={12}  lg={1} className="my-auto  d-md-none d-lg-block"></Col>
                                <Col xs={12} lg={4} md={6} className='my-auto p-relative'>
                                
                                <span className='lineJourneyNew' ></span>
                                <span className='lineJourney' ></span>
                                    <div className='text-start paddJourn' data-aos="fade-up" data-aos-duration="2800">
                                        <div className='heading-all text-uppercase mb-2'>
                                            <h2>2020 – LAUNCHED LAYA MANSION</h2>
                                        </div>
                                        <div>
                                            <p className='text-para mb-0'>
                                            Your home at Laya Mansion is your sanctuary and we focus on offering everything we can to make you feel happy at home here. Laya Mansion has a total of 235 residences.

                                            </p>
                                        </div>
                                    </div>
                                    
                                </Col>
                                <Col xs={12}  lg={3} className="my-auto d-md-none d-lg-block"></Col>
                                </Row>
                                <Row className=''>
                                <Col xs={12}  lg={3} className="my-auto d-md-none d-lg-block"></Col>
                                <Col xs={12} md={6} lg={4} className='my-auto'>
                                <span className='lineJourneyNew' ></span>
                                    <div className='text-end paddJourn' data-aos="fade-up" data-aos-duration="2400">
                                        <div className='heading-all text-uppercase my-3'>
                                            <h2>2023 – LAYA HEIGHTS HANDOVER</h2>
                                        </div>
                                        <div>
                                            <p className='text-para mb-0'>
                                            Laya Heights will offer a wide range of amenities such as an outdoor cinema to a double-height luxurious lobby, with Studio & 1 Bedroom options.

                                            </p>
                                        </div>
                                    </div>
                                    
                                </Col>
                                <Col xs={12}  lg={1} className="my-auto  d-md-none d-lg-block "></Col>
                                <Col xs={12} md={6} lg={4} className="my-auto">
                                    <div data-aos="fade-up" data-aos-duration="2600"><img src={journey4} className="img-fluid" alt="Laya Developers" /></div>
                                </Col>
                                </Row>
                                <Row className='rowRev'>
                                <Col xs={12} md={6} lg={4} className="my-auto">
                                    <div data-aos="fade-up" data-aos-duration="2600"><img src={journey5} className="img-fluid" alt="Laya Developers" /></div>
                                </Col>
                                <Col xs={12}  lg={1} className="my-auto  d-md-none d-lg-block"></Col>
                                <Col xs={12} lg={4} md={6} className='my-auto p-relative'>
                                
                                <span className='lineJourneyNew' ></span>
                                <span className='lineJourney' ></span>
                                    <div className='text-start paddJourn' data-aos="fade-up" data-aos-duration="2800">
                                        <div className='heading-all text-uppercase mb-2'>
                                            <h2>2023 – LAYA COURTYARD LAUNCH </h2>
                                        </div>
                                        <div>
                                            <p className='text-para mb-0'>
                                            LAYA Courtyard is one of our upcoming projects which is yet to be unveiled. This project will offer unmatched amenities in one of the most thriving neighborhoods of the city.

                                            </p>
                                        </div>
                                    </div>
                                    
                                </Col>
                                <Col xs={12}  lg={3} className="my-auto d-md-none d-lg-block"></Col>
                                </Row>
                                <Row className=''>
                                <Col xs={12}  lg={3} className="my-auto d-md-none d-lg-block"></Col>
                                <Col xs={12} md={6} lg={4} className='my-auto'>
                                <span className='lineJourneyNew' ></span>
                                    <div className='text-end paddJourn' data-aos="fade-up" data-aos-duration="2400">
                                    <div className='heading-all text-uppercase mb-2'>
                                            <h2>Our Future</h2>
                                        </div>
                                        <div>
                                            <p className='text-para mb-0'>
                                            We thrive towards developing bigger and better projects in the coming years to deliver our promise to our customers.

                                            </p>
                                        </div>
                                    </div>
                                    
                                </Col>
                                <Col xs={12}  lg={1} className="my-auto  d-md-none d-lg-block "></Col>
                                <Col xs={12} md={6} lg={4} className="my-auto">
                                    <div data-aos="fade-up" data-aos-duration="2600"><img src={journey3} className="img-fluid" alt="Laya Developers" /></div>
                                </Col>
                                </Row>
                                
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
