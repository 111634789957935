import React, { Component } from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import {  FaMapMarkerAlt } from 'react-icons/fa'
import banner1 from "../../Assets/Images/property/mansion/LMbg.webp"

export default class Banner extends Component {
    render() {
        return (
            <section>
                <Container fluid className='px-0'>
                    <Row className='g-0'>
                        <Col xs={12} md={12} lg={12}>
                            <Carousel className='bannerCarousel'>
                                <Carousel.Item  interval={3000}>
                                    <img className="d-block w-100" src={banner1} alt="First slide" />
                                    <Container className='centered'>
                                        <Row className='justify-content-center text-center text-white'>
                                            <Col xs={12} md={12} lg={12}>
                                                <div data-aos="fade-up" data-aos-duration="1500">
                                                    <h1 className='main-heading mb-0'>LAYA MANSION</h1>
                                                </div>
                                                
                                                <div data-aos="fade-up" data-aos-duration="1700">
                                                    <h5><FaMapMarkerAlt /> JUMEIRAH VILLAGE CIRCLE</h5>
                                                </div>
                                                <div data-aos="fade-up" data-aos-duration="2000">
                                                    <p>Studio, 1 & 2 Bedroom Apartments</p>
                                                </div>
                                                
                                            </Col>
                                            <Col xs={12} md={6} lg={5}>
                                                
                                                <div className='projDet' data-aos="fade-up" data-aos-duration="2200">
                                                    <div className='text-black'>
                                                        <h5 className='mb-0'>Sold out and Handed over in 2020</h5>
                                                    </div>
                                                </div>
                                                
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Carousel.Caption>
                                        <div className='scrollDiv'>
                                            <a href='#mansion' className='text-decoration-none text-white'>scroll 
                                             </a>
                                        </div>
                                        <div className='scrollDiv'>
                                            <a href='#mansion' className='text-decoration-none text-white'>
                                                <span className='line' ></span>
                                                <div className='arrowSpan mouse-croll'>
                                                    <div className="down-arrow-1"></div>
                                                    <div className="down-arrow-2"></div>
                                                </div>
                                            </a>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
