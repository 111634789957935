import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import map from '../../Assets/Images/map.webp'
import layaresidence from '../../Assets/Images/layaresidence.webp'
import layaheight from '../../Assets/Images/layaheight.webp'
import layamansion from '../../Assets/Images/layamansion.webp'
import courtyard from "../../Assets/Images/LCabout.webp"
import { Link } from 'react-router-dom'
import { GeoAltFill } from 'react-bootstrap-icons'

export default class Community extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0
        };

    };

    myFunction(e) {

        this.setState((state) => {
            return {
                status: e === this.state.status ? 0 : e
            }
        });
        //alert(this.state.status == 1 ? 'active': '');

    }

    render() {
        return (
            <section className='my-5'>
                <Container>
                    <Row className=''>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='justify-content-center'>
                                <Col xs={12} md={8} lg={6}>
                                    <div className='heading-all text-uppercase text-center' data-aos="fade-up" data-aos-duration="2000">
                                        <h1>Our Residential Community</h1>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12}>

                                    <div className='mapContComm'>
                                        <center><img src={map} className="img-fluid mapIMg" alt="Laya Developers" /></center>
                                        <div className="lg-hotspot lg-hotspot--top-left">

                                            <div className="lg-hotspot__button" title={'Laya Courtyard'} onClick={(e) => this.myFunction(e.currentTarget.title)}><GeoAltFill></GeoAltFill></div>
                                            <div className="pulse"></div>
                                            <div className="lg-hotspot__label" id={this.state.status === 'Laya Courtyard' ? 'Active' : 'no'}>
                                                <Link to="/laya-courtyard"><img src={courtyard} className="img-fluid" alt="Laya Developers" />

                                                </Link>
                                                <Link to="/laya-courtyard" className='text-decoration-none text-black'>
                                                    <h6 className='p-1'>Laya Courtyard</h6>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="lg-hotspot lg-hotspot--top-right">
                                            <div className="lg-hotspot__button" title={'Laya Height'} onClick={(e) => this.myFunction(e.currentTarget.title)} ><GeoAltFill></GeoAltFill></div>
                                            <div className="pulse"></div>
                                            <div className="lg-hotspot__label" id={this.state.status === 'Laya Height' ? 'Active' : 'no'}>
                                                <Link to="/laya-heights">
                                                    <img src={layaheight} className="img-fluid" alt="Laya Developers" />

                                                </Link>
                                                <Link to="/laya-heights" className='text-decoration-none text-black'>
                                                    <h6 className='p-1'>Laya Height</h6>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="lg-hotspot lg-hotspot--top-center">
                                            <div className="lg-hotspot__button" title={'Laya Residence'} onClick={(e) => this.myFunction(e.currentTarget.title)}><GeoAltFill></GeoAltFill></div>
                                            <div className="pulse"></div>
                                            <div className="lg-hotspot__label" id={this.state.status === 'Laya Residence' ? 'Active' : 'no'}>
                                                <Link to="/laya-residences"><img src={layaresidence} className="img-fluid" alt="Laya Developers" />

                                                </Link>
                                                <Link to="/laya-residences" className='text-decoration-none text-black'>
                                                    <h6 className='p-1'>Laya Residence</h6>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="lg-hotspot lg-hotspot--bottom-center">
                                            <div className="lg-hotspot__button" title={'Laya Mansion'} onClick={(e) => this.myFunction(e.currentTarget.title)} ><GeoAltFill></GeoAltFill></div>
                                            <div className="pulse"></div>
                                            <div className="lg-hotspot__label " id={this.state.status === 'Laya Mansion' ? 'Active' : 'no'} >
                                                <Link to="/laya-mansion"><img src={layamansion} className="img-fluid" alt="Laya Developers" />
                                                </Link>
                                                <Link to="/laya-mansion" className='text-decoration-none text-black'>
                                                    <h6 className='p-1'>Laya Mansion</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
