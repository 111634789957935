import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import mansion1 from "../../Assets/Images/property/mansion/LMabout.webp"
import area from "../../Assets/Images/area.png"
import bed from "../../Assets/Images/bed.png"
import bath from "../../Assets/Images/bath.png"
export default function About() {
    return (
        <section className='py-5' id='mansion'>
            <div className=' aboutSec'>
                <div className="containerbackground">
                    <h1>LAYA</h1>
                </div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <div className='heading-all text-uppercase text-start mb-4 mt-5' data-aos="fade-right" data-aos-duration="1500">
                                        <h2>HOME IS THE STARTING PLACE
                                            FOR LOVE, HOPE AND DREAMS</h2>
                                    </div>
                                    <div data-aos="fade-right" data-aos-duration="2000">
                                        <p><b>Explore a life of comfort and joy amid a tranquil setting marvelously
                                            crafted ready-to-move-in residences in Jumeirah Village Circle, Dubai. </b></p>

                                        <p className='text-para'>
                                            Our signature LAYA Mansion are made with breathtaking luxury designs and that create an ornamentally stylized aura spread over the full complex. Laya Mansion has a total of 235 spacious studios, and 1 and 2-bedroom apartments, and the architectural structure of the mansion is inspired by French Baroque Architecture that gives the essence of evolution from the classical to the renaissance time period.

                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6} className="my-auto">
                                    <div className='p-relative  mx-0 mx-md-3 mx-lg-5'>
                                        <div className='boxPrimary' data-aos="fade-right" data-aos-duration="1500"></div>
                                        <img data-aos="fade-right" data-aos-duration="2000" src={mansion1} className="img-fluid p-4" alt="Laya Developers" />
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3'>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={area} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main ' data-aos="fade-up" data-aos-duration="1900"><h3>LIVING ROOM</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"><p className='text-para pb-2'>Exquisite double-glazed porcelain Italian Walnut flooring, and expansive balconies with direct pool and park views.</p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={bath} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main ' data-aos="fade-up" data-aos-duration="1900"><h3>BATHROOM</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"><p className='text-para pb-2'>Enjoy a marble quartz countertop complemented with designer accessories to ensure a luxurious experience.</p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={bed} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main 'data-aos="fade-up" data-aos-duration="1900"><h3>ENSUITE BEDROOM</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"><p className='text-para pb-2'>Double-glazed aluminum windows, built cove lighting and spacious wardrobes enhance the natural beauty of your bedroom.</p></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}
