import React from 'react'
import About from '../Components/Mansion/About'
import Amenities from '../Components/Mansion/Amenities'
import Banner from '../Components/Mansion/Banner'
import Bedrooms from '../Components/Mansion/Bedrooms'
import Location from '../Components/Mansion/Location'
import Gallery from '../Components/Mansion/Gallery'
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default function Mansion() {

  return (
    <main>
      <HelmetProvider><Helmet><title>Laya Mansion | Laya Developers</title></Helmet></HelmetProvider>
        <Banner />
        <About />
        <Bedrooms />
        <Amenities />
        <Location />
        <Gallery />
    </main>
  )
}
