import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import home1 from '../../Assets/Images/home1.png'
import home2 from '../../Assets/Images/home2.gif'
export default function Animation() {
    const [style, setStyle] = useState({ display: 'none' });
    const [otherStyle, setOtherStyle] = useState({ display: 'block' });
    const [styleMain, setStyleMain] = useState({ display: 'flex' });
    const [styleNew, setStyleNew] = useState({ display: 'none' });
    const callTimer = (e) => {
        e.preventDefault();
        
        setTimeout(() =>
            setStyleMain({ display: 'none' })
            , 2800);
            setTimeout(() =>
            setStyleNew({ display: 'block' })
            , 2800);
           
    }
  return (
    <section className='' id='about'>
         
            <div className='bgAbout'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={7} lg={6}>
                            <div className='heading-all text-uppercase text-center' data-aos="fade-up" data-aos-duration="1500">
                                <h2>Open the door to discover the inspiration behind LAYA</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <div className='text-center d-flex justify-content-center' data-aos="fade-up" data-aos-duration="2000">
                                <div className='imageHoverCont' style={styleMain}>
                                    <div className='imgMainHover my-auto' style={otherStyle} onClick={e => {
                                        setOtherStyle({ display: 'none' });
                                        setStyle({ display: 'block' });
                                        setTimeout(() =>
                                            callTimer(e)
                                            , 1000);


                                    }}
                                    ><img src={home1} className="img-fluid roundedCircle pointerCursor" alt="Laya 
                                         Developers" /></div>
                                    <div className='imgHoverAfter' style={style}>
                                        {/* <div id="wave">
                                            <span className="dot"></span>
                                            <span className="dot"></span>
                                            <span className="dot"></span>
                                        </div> */}
                                        <img src={home2} className="img-fluid roundedCircle" alt="Laya 
                                         Developers" />
                                    </div>

                                </div>
                                <div className='bgMain p-3 rounded50 text-white' style={styleNew}>
                                    <p className='mb-0 '>Laya is a Sanskrit term denoting a "residence", "place of rest", or "house" according to Vāstuśāstra literature.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
  )
}

