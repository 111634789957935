import React from 'react'
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap'
import banner1 from "../../Assets/Images/brandPromise.webp"
export default function Banner(){

        return (
            <section>
            <Container fluid className='px-0'>
                <Row className='g-0'>
                    <Col xs={12} md={12} lg={12}>
                        <Carousel className='bannerCarousel'>
                            <Carousel.Item  interval={3000}>
                                <img className="d-block w-100" src={banner1} alt="First slide" />
                                <Container className='centered'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} md={6} lg={6}>
                                            <div data-aos="fade-up" data-aos-duration="1500">
                                                <h1 className='main-heading'>Thank you for your payment</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                               
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
        )
    
}
