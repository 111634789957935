import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import brand1 from "../../Assets/Images/brand1.webp"

export default class Brands extends Component {
    render() {
        return (
            <section className='py-5' id='partner'>
                <div className=' aboutSec'>
                    <div className="containerbackground">
                        <h1>LAYA</h1>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className='heading-all text-uppercase text-start mb-4 mt-5' data-aos="fade-right" data-aos-duration="1500">
                                            <h2>Building Bridges With Strategic Collaborations</h2>
                                        </div>
                                        <div data-aos="fade-right" data-aos-duration="2000">
                                            <p className='text-para'>
                                            We greatly value having long-lasting, strategic partnerships with some of the most renowned global brands that create immense value for our clients. Our dedication to forming strong connections with the region's best brands has established us as a leading player in the industry. 

                                            </p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="my-auto">
                                        <div className='p-relative  mx-0 mx-md-3 mx-lg-5'>
                                            <div className='boxPrimary'  data-aos="fade-right" data-aos-duration="1500"></div>
                                            <img src={brand1}  data-aos="fade-right" data-aos-duration="2000" className="img-fluid p-4" alt="Laya Developers" />
                                        </div>
                                    </Col>
                                </Row>
                               
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}
