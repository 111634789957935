import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Counter from './Counter'
export default class Numbers extends Component {
    render() {
        return (
            <section>
                <div  className='bgAbout'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={7} lg={5} className="my-auto">
                            <div className='heading-all text-uppercase text-center' data-aos="fade-up" data-aos-duration="2000">
                                <h1>LAYA in Numbers as of 2023</h1>
                            </div>
                        </Col>
                    </Row>
                    
                </Container>
                </div>
                
                <div className='pb-5'>
                    <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={4} lg={4} className="my-auto">
                            <div className=' text-uppercase text-center'>
                                <h6 className=''>Completed</h6>
                                <div className='counterMain'>
                                    <h1 className='text-main'><Counter number={8} /><span className="fontSize-30">+</span></h1>
                                </div>
                                <h6 className=''>years</h6>
                                <h6 className=''>in the industry</h6>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4} className="my-auto">
                            <div className=' text-uppercase text-center'>
                                <h6 className=''>Over</h6>
                                <div className='counterMain'>
                                    <h1 className='text-main'><Counter number={804} /></h1>
                                </div>
                                <h6 className=''>Apartments</h6>
                                <h6 className=''>Delivered</h6>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4} className="my-auto">
                            <div className=' text-uppercase text-center'>
                                <h6 className=''>Developed</h6>
                                <div className='counterMain'>
                                    <h1 className='text-main'><Counter number={800} /><span className="fontSize-30">M</span></h1>
                                </div>
                                <h6 className=''>AED</h6>
                                <h6 className=''>Worth Assets</h6>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </section>

        )
    }
}
