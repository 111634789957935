import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import useScrollDirection from '../../Hooks/Scroll'
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../../Assets/Images/logo.png';
import { FaChevronCircleLeft } from 'react-icons/fa';
export default function HeaderNav() {
  
    const scrollDirection = useScrollDirection();

    const location = useLocation();
    const [style, setStyle] = useState();
    const [joinStyle, setJoinStyle] = useState();
    // console.log(location.pathname);
    const [newStyle, setNewStyle] = useState();
    const [brandStyle, setBrandStyle] = useState();
    useEffect(() => {
        if(location.pathname === '/our-brand') { 
            setNewStyle('d-none'); 
            setStyle('d-none'); 
            setJoinStyle('d-none'); 
            setBrandStyle('d-flex'); 
        }else if (location.pathname === '/laya-residences' || location.pathname === '/laya-mansion' || location.pathname === '/laya-heights'|| location.pathname === '/laya-courtyard'){
            setNewStyle('d-none'); 
            setStyle('d-flex'); 
            setJoinStyle('d-none'); 
            setBrandStyle('d-none'); 
        }else if(location.pathname === '/careers' || location.pathname === '/become-an-agent'){
            setNewStyle('d-none'); 
            setStyle('d-none'); 
            setJoinStyle('d-flex'); 
            setBrandStyle('d-none'); 
        }else{
            setNewStyle('d-flex'); 
            setStyle('d-none'); 
            setJoinStyle('d-none'); 
            setBrandStyle('d-none');
        }
      }, [location.pathname]);
    return (
        <Navbar collapseOnSelect className={`navFixed ${ scrollDirection === "down" ? "Hide" : "show"}`}  expand="lg" bg="navBg" variant="dark">
            <Container className=''>
                <Navbar.Brand >
                    <LinkContainer to="/"><img src={logo} className="img-fluid pointerCursor" width="70" alt="logo" /></LinkContainer>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className={`ms-auto navLink mainMenu ${newStyle} `} >
                        <LinkContainer to="/" ><Nav.Link >Home</Nav.Link></LinkContainer>
                        <Nav.Link onClick={e => {
                            setNewStyle("d-none");
                            setStyle("d-flex");


                        }}>OUR PROJECTs</Nav.Link>

                        {/* <LinkContainer to="/our-partnerships"><Nav.Link>Our Partnerships</Nav.Link></LinkContainer> */}
                        <LinkContainer to="/our-brand" onClick={e => {
                            setBrandStyle("d-flex");
                            setNewStyle("d-none");
                            setStyle("d-none");


                        }} ><Nav.Link>Our Brand</Nav.Link></LinkContainer>
                        <LinkContainer to="/media"><Nav.Link>News & Blogs</Nav.Link></LinkContainer>
                        <Nav.Link onClick={e => {
                            setNewStyle("d-none");
                            setJoinStyle("d-flex");


                        }}>Join Us</Nav.Link>
                        <LinkContainer to="/contact-us"><Nav.Link>Contact Us</Nav.Link></LinkContainer>
                        <LinkContainer to="/pay"><Nav.Link>Pay Now</Nav.Link></LinkContainer>
                    </Nav>
                    <Nav className={`ms-auto navLink joinMenu ${joinStyle} `} >
                        <Nav.Link onClick={e => {
                            setNewStyle("d-flex");
                            setJoinStyle("d-none");


                        }}><FaChevronCircleLeft className='fs-20' /></Nav.Link>
                        <Nav.Link><b>JOIN US</b></Nav.Link>
                        <LinkContainer to="/careers"><Nav.Link>Careers</Nav.Link></LinkContainer>
                        <LinkContainer to="/become-an-agent"><Nav.Link>Become An Agent</Nav.Link></LinkContainer>
                    </Nav>
                    <Nav className={`ms-auto navLink portfolioMenu ${style} `} >
                        <Nav.Link onClick={e => {
                            setNewStyle("d-flex");
                            setStyle("d-none");


                        }}><FaChevronCircleLeft className='fs-20' /></Nav.Link>
                        <Nav.Link><b>OUR Projects</b></Nav.Link>
                        <LinkContainer to="/laya-residences"><Nav.Link>Laya Residences</Nav.Link></LinkContainer>
                        <LinkContainer to="/laya-mansion"><Nav.Link>Laya Mansion</Nav.Link></LinkContainer>
                        <LinkContainer to="/laya-heights"><Nav.Link>Laya Heights</Nav.Link></LinkContainer>
                        <LinkContainer to="/laya-courtyard"><Nav.Link>Laya Courtyard</Nav.Link></LinkContainer>
                    </Nav>
                    <Nav className={`ms-auto navLink brandMenu ${brandStyle} `} >
                        <Nav.Link onClick={e => {
                            setBrandStyle("d-none");
                            setNewStyle("d-flex");
                            setStyle("d-none");


                        }}><FaChevronCircleLeft className='fs-20' /></Nav.Link>
                        <Nav.Link><b>OUR Brand</b></Nav.Link>
                        <Nav.Link href="#about">About Us</Nav.Link>
                        <Nav.Link href="#promise">Our Promise</Nav.Link>
                        <Nav.Link href="#leaders">Our Leaders</Nav.Link>
                        <Nav.Link href="#journey">Our Journey</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
