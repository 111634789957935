import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import brandIcon1 from "../../Assets/Images/brandIcon1.png"
import brandIcon2 from "../../Assets/Images/brandIcon2.png"
import brandIcon3 from "../../Assets/Images/brandIcon3.png"
import brandIcon4 from "../../Assets/Images/brandIcon4.png"
import brandPromise from "../../Assets/Images/brandPromise.webp"
import leader from "../../Assets/Images/leader-1.webp"
export default class Why extends Component {
    render() {
        return (
            <section className='mb-5'>
                <div className='bgGrey p-5'>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-3'>
                                    <Col xs={12} md={6} lg={3}>
                                        <div className='bg-white borderMain featureBox p-4 text-center'  data-aos="fade-up" data-aos-duration="1500">
                                            <div className='mb-3'  data-aos="fade-up" data-aos-duration="1700"><center><img src={brandIcon1} className="img-fluid" alt="Laya Developers" /></center></div>
                                            <div className='text-uppercase'  data-aos="fade-up" data-aos-duration="1900"><h6>HIGH QUALITY AND
                                                TOP-NOTCH FINISHING</h6></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={3}>
                                        <div className='bg-white borderMain featureBox p-4 text-center' data-aos="fade-up" data-aos-duration="2000">
                                            <div className='mb-3' data-aos="fade-up" data-aos-duration="2200"><center><img src={brandIcon2} className="img-fluid" alt="Laya Developers" /></center></div>
                                            <div className='text-uppercase' data-aos="fade-up" data-aos-duration="2400"><h6>EXCELLENT <br />PAYMENT
                                                PLANS
                                            </h6></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={3}>
                                        <div className='bg-white borderMain featureBox p-4 text-center' data-aos="fade-up" data-aos-duration="2500">
                                            <div className='mb-3' data-aos="fade-up" data-aos-duration="2700"><center><img src={brandIcon3} className="img-fluid" alt="Laya Developers" /></center></div>
                                            <div className='text-uppercase' data-aos="fade-up" data-aos-duration="2900"><h6>TIMELY <br />DELIVERY</h6></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={3}>
                                        <div className='bg-white borderMain featureBox p-4 text-center' data-aos="fade-up" data-aos-duration="3000">
                                            <div className='mb-3' data-aos="fade-up" data-aos-duration="3000"><center><img src={brandIcon4} className="img-fluid" alt="Laya Developers" /></center></div>
                                            <div className='text-uppercase' data-aos="fade-up" data-aos-duration="3000"><h6>TRUST & <br />CREDIBILITY</h6></div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='py-5' id='promise'>
                    <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-0'>
                                    <Col xs={12} md={12} lg={6} className="my-auto">
                                        <div  data-aos="fade-up"  data-aos-offset="300" data-aos-duration="1500">
                                            <img src={brandPromise} className="img-fluid brndImg" alt="Laya Developers" />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={6} className="px-5 my-auto">
                                        <div className='heading-all text-uppercase text-start mb-3 '   data-aos="fade-up"  data-aos-offset="300" data-aos-duration="1500">
                                            <h2>OUR PROMISE</h2>
                                        </div>
                                        <div  data-aos="fade-up"  data-aos-offset="300" data-aos-duration="2000">
                                            <p className='text-para'>
                                                Our philosophy is grounded in the belief that the difference between a
                                                good home and the perfect home is the commitment of the real estate
                                                team involved. And we at Laya, prioritize the unique journey of our clients.
                                            </p>
                                        </div>
                                        <div className='text-main text-italic'   data-aos="fade-up"  data-aos-offset="300" data-aos-duration="2500">
                                            <ul className='listPromise'>
                                                <li >
                                                    World-Class Customer Service
                                                </li>
                                                <li>Long-Lasting Client Relationships
                                                </li>
                                                <li>Masterfully Developed Homes</li>
                                                <li>Top-Tier Payment Plans</li>
                                                <li>Reliable and Trustworthy</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='bgGreyGrad mb-5' id='leaders'>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Row>

                                    <Col xs={12} md={12} lg={6} className="px-5 mt-auto">
                                        <div className=' py-5'>
                                        <div className='heading-all text-uppercase text-start mb-4 '  data-aos="fade-up" data-aos-duration="1500">
                                            <h2>OUR LEADERS</h2>
                                        </div>
                                        <div className='leaderCont'>
                                            <div className='leaderTest '  data-aos="fade-up" data-aos-duration="2000">
                                                <p>“Leadership is the
                                                    capacity to translate
                                                    vision into reality”</p>
                                            </div>
                                            <div className='text-main leaderName ' data-aos="fade-up" data-aos-duration="2000">
                                                <p>– Warren Bennis</p>
                                            </div>
                                        </div>
                                        <div  data-aos="fade-up" data-aos-duration="2500">
                                            <p className='text-para'>
                                                A true leader upholds the core values of the company and at Laya, we are
                                                no exception. Our leaders are driven by the goal to provide the best real
                                                estate services in Dubai.
                                            </p>
                                            <p className='text-para'>
                                                Our vision is to completely transform the real estate experience and help
                                                our clients build their dream home or perfect investment opportunity.
                                            </p>
                                        </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={6} className="my-auto">
                                        <div  data-aos="fade-up" data-aos-duration="2500">
                                            <center> <img src={leader} className="img-fluid" width={570} alt="Laya Developers" /></center>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}
