
import React, { useEffect } from "react";
import './Assets/Styles/App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from "./Pages/Home";
import Error from "./Pages/Error";
import Clear from "./Pages/Clear";
import Contact from "./Pages/Contact";
import Blogs from "./Pages/Blogs";
import SingleBlog from "./Pages/SingleBlog";
import Careers from "./Pages/Careers";
import Partnership from "./Pages/Partnership"
import Brand from "./Pages/Brand"
import Residence from "./Pages/Residence"
import Mansion from "./Pages/Mansion"
import Heights from "./Pages/Heights"
import Courtyard from "./Pages/Courtyard"
import Pay from "./Pages/PayNow"
import PayComplete from "./Pages/PayComplete";
import PrivacyPolicy from "./Pages/PrivacyPolicy"
import BecomeAgent from "./Pages/BecomeAgent"
import Articles from "./Pages/Articles"
import News from "./Pages/News"
import TermsCondition from "./Pages/TermsCondition"
import PayTerms from "./Pages/PayTerms"
// eslint-disable-next-line
import "swiper/css/bundle";
import { Route, Routes, useLocation } from "react-router-dom";
import {useLayoutEffect} from 'react';
import HeaderNav from "./Components/Layout/HeaderNav";
import Footer from "./Components/Layout/Footer";
const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 
function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
  <Wrapper>
    <HeaderNav />
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path='*' element={<Error />} />
      <Route  path="/clear" element={<Clear />} />
      <Route  path="/contact-us" element={<Contact />} />
      <Route  path="/media" element={<Blogs />} />
      <Route  path="/blog/:slug" element={<SingleBlog />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/our-partnerships" element={<Partnership />} />
      <Route path="/our-brand" element={<Brand />} />
      <Route path="/laya-residences" element={<Residence />} />
      <Route path="/laya-heights" element={<Heights />} />
      <Route path="/laya-mansion" element={<Mansion />} />
      <Route path="/laya-courtyard" element={<Courtyard />} />
      <Route path="/pay" element={<Pay />} />
      <Route path="/payComplete" element={<PayComplete />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/become-an-agent" element={<BecomeAgent />} />
      <Route path="/blogs" element={<Articles />} />
      <Route path="/news" element={<News />} />
      <Route path="/payment-terms" element={<PayTerms />} />
      <Route path="/terms-and-conditions" element={<TermsCondition />} />
    </Routes>
    <Footer />
    </Wrapper>
    </>
  );
}

export default App;
