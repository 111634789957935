import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import height1 from "../../Assets/Images/property/heights/LHabout.webp"
import area from "../../Assets/Images/60p.png"
import bath from "../../Assets/Images/40p.png"
export default function About() {
    return (
        <section className='py-5 ' id='height'>
            <div className=' aboutSec'>
                <div className="containerbackground">
                    <h1>LAYA</h1>
                </div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <div className='heading-all text-uppercase text-start mb-4 mt-5' data-aos="fade-right" data-aos-duration="1500">
                                        <h2>A BUILDING THAT FEELS
                                            LIKE A PRIVATE CLUB</h2>
                                    </div>
                                    <div data-aos="fade-right" data-aos-duration="2000">
                                        <p><b>Explore a quaint new community for a serene lifestyle.
                                            Elegant residences in Dubai Studio City.  </b></p>
                                        <p><b>Lead a private lifestyle in LAYA Heights. </b></p>

                                        <p className='text-para'>
                                            Awaken your senses with every breath, as you redefine your premium lifestyle in Dubai. LAYA Heights offers you a stunning collection of Studio and 1-Bedroom high-end residences situated in one of Dubai's most thriving neighborhoods, Dubai Studio City. The residential project exudes a sense of refinement and modern luxury with its sweeping views of the tranquil neighborhood and glimpses of the promenade and nearby parks.
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6} className="my-auto">
                                    <div className='p-relative mx-0 mx-md-3 mx-lg-5'>
                                        <div className='boxPrimary' data-aos="fade-right" data-aos-duration="1500"></div>
                                        <img src={height1} data-aos="fade-right" data-aos-duration="2000" className="img-fluid p-4" alt="Laya Developers" />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} className="my-auto">
                                    <div className='text-center mt-4' data-aos="fade-up" data-aos-duration="1200">
                                        <a href="https://pro.frametalks.com/sliceview/layaheights" target="_blank" rel="noreferrer" className='text-decoration-none'>
                                            <Button className='btn-mainLgOut'>
                                                <h4 className='mb-0'>VIEW 360 Tour</h4>
                                            </Button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3 justify-content-center'>
                                <Col xs={12} md={12} lg={12}>
                                    <div className='heading-all text-uppercase text-center mb-1 mt-5' data-aos="fade-up" data-aos-duration="1200">
                                        <h2>Payment plan</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={area} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase  ' data-aos="fade-up" data-aos-duration="1900"><h3>Down Payment</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"></div>
                                    </div>
                                </Col>
                                {/* <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={bed} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase  ' data-aos="fade-up" data-aos-duration="1900"><h3>Within 30 Days</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"></div>
                                    </div>
                                </Col> */}
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={bath} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase  ' data-aos="fade-up" data-aos-duration="1900"><h3>Upon Handover</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}
