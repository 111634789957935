import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/Careers/Banner'
import Join from '../Components/Careers/Join'

export default function Careers() {
 
  return (
    <main>
      <HelmetProvider>
      <Helmet> <title>Careers | Laya Developers</title></Helmet>
      </HelmetProvider>
      <Banner/>
      <Join />
    </main>
  )
}
