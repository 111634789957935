import React, { useEffect, useState } from 'react'
import axios from "axios";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper";
import { Container, Row, Col } from 'react-bootstrap';

export default function Testimonial() {
    const [testi, setTesti] = useState([])
    useEffect(() => {
        async function getTesti() {
            try {
                const testimonial = await axios.post("https://backend.layadevelopers.com/api/clienttestimonials"
              )
              setTesti(testimonial.data)
            } catch (error) {
                console.log(error)
            }
        }
        getTesti()
    }, [])
    return (

        <section className='my-5'>
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col xs={12} md={5} lg={6} className="my-auto">
                                <div className=' p-3 p-lg-5 p-md-3'>
                                <div className='heading-all text-uppercase' data-aos="fade-right" data-aos-duration="1500">
                                        <h1>Testimonials</h1>
                                    </div>
                                    <div className='my-5' data-aos="fade-right" data-aos-duration="2000">
                                        <p className='text-para'>
                                       Check out what our clients have to say about us.
                                        </p>
                                    </div>
                                    <div data-aos="fade-right" data-aos-duration="2500"> 
                                    <Link to={"/our-brand"} className='text-decoration-none text-sec'> <p className='text-sec'> KNOW MORE ABOUT US &nbsp;<span>&#10230;</span></p></Link>
                                                    </div>
                                </div>
                                    
                            </Col>
                            <Col xs={12} md={7} lg={6} className="my-auto">
                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={20}
                                    mousewheel={true}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                      breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 20,
                                        },
                                    }}
                                    direction={"vertical"}
                                    modules={[Autoplay]}
                                    className="myTestiSwiper px-5"

                                >
                                    {
                                testi.map((testis, i) => {
                                    return (
                                    <SwiperSlide key={testis.id}>
                                        <div className='testiCont p-3 rounded'>
                                            <div className='d-flex justify-content-start mb-2'>
                                                {/* <div className='me-3 my-auto'>
                                                    <img className="img-fluid" src={'https://backend.layadevelopers.com/'+testis.image} alt="Laya Developers" />
                                                </div> */}
                                                <div className='testiDesc my-auto text-start'>
                                                    <p className='mb-0'><b>{testis.name}</b></p>
                                                    <small>{testis.designation}</small>
                                                </div>
                                            </div>
                                            <div className='fs-14 text-start'>
                                                <p>{testis.feedback}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    )
                                })
                            }
                                   
                                </Swiper>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}
