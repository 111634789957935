import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import About from '../Components/Courtyard/About';
import Banner from '../Components/Courtyard/Banner';
import Subscribe from '../Components/Courtyard/Subscribe';

export default function Courtyard() {
 
  return (
    <main>
      <HelmetProvider>
      <Helmet> <title>Laya Courtyard | Laya Developers</title></Helmet>
      </HelmetProvider>
      <Banner />
      <About />
      <Subscribe />
    </main>
  )
}
