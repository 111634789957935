import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Col, Container, Row, Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { FaClock } from 'react-icons/fa';
import moment from "moment";
import { Link } from 'react-router-dom';
export default function BlogDetail() {

    const [blog, setBlog] = useState([]);
    const [popular, setPopular] = useState([])
    const [news, setNews] = useState([])
    const { slug } = useParams();
    useEffect(() => {
        async function getblogDetails() {
            try {
                const blogs = await axios.post("https://backend.layadevelopers.com/api/blog/" + slug
                )

                setBlog(blogs.data)

            } catch (error) {
                console.log(error)
            }
        }
        getblogDetails()
       
            async function getLatestNews() {
                try {
                    const news = await axios.post("https://backend.layadevelopers.com/api/maylike/" + slug)
                    setNews(news.data)
                } catch (error) {
                    console.log(error)
                }
            }
            getLatestNews()
            async function getPopular() {
                try {
                    const popular = await axios.post("https://backend.layadevelopers.com/api/popular")
                    setPopular(popular.data)
                } catch (error) {
                    console.log(error)
                }
            }
            getPopular()
    }, [slug])
    
    const first = blog[0];

    return (
        <section className='aos-init aos-animate'>
            <div className='bgGrey'>
                <Container className='py-5'>
                    <Row>
                        <Col xs={12} md={8} lg={8}>

                            <div className="heading-all text-uppercase text-main " data-aos="fade-right" data-aos-duration="1500" data-aos-offset="300">
                                <h1>{first?.title}</h1>
                            </div>
                            <div className='d-flex justify-content-start mb-3' data-aos="fade-right" data-aos-duration="2000" data-aos-offset="300">
                                <div className='me-2 my-auto text-sec'><FaClock /></div>
                                <div className='my-auto'>{moment(first?.publish_date).format("MMMM D, YYYY")}{" "}</div>
                            </div>


                        </Col>
                    </Row>
                </Container>
            </div>

            <Container className='my-5'>
                <Row>
                    <Col xs={12} md={8} lg={8}>
                        <Row className='g-3'>

                            <Col xs={12} md={12} lg={12}>
                                <div className='blogSingleDesc' data-aos="fade-up" data-aos-duration="1500">
                                    <div dangerouslySetInnerHTML={{ __html: first?.description }}></div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <div className='d-flex justify-content-start flex-wrap' data-aos="fade-up" data-aos-duration="2000">
                                    <div className='me-2'>TAGS: </div>
                                    {
                                        first?.tags.map((tags, i) => {
                                            return (
                                                <div className='text-black me-2' key={tags.id}><i>{tags.title}</i></div>
                                            )
                                        })
                                    }

                                </div>
                            </Col>
                        </Row>
                        
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                        <Row className='g-3'>
                            <Col xs={12} md={12} lg={12}>
                                <div className="bgMain p-2 text-uppercase text-white mb-3" data-aos="fade-up" data-aos-duration="1500">
                                    <h6 className='mb-0'>MOST POPULAR</h6>
                                </div>
                            </Col>
                            {
                                popular.map((popular, i) => {
                                    return (
                                        <Col xs={12} md={12} lg={12}  key={popular.id}>
                                <Card className='bg-transparent border-0'>
                                    <Row className='g-0'>
                                        <Col xs={4} md={4} lg={5} className="my-auto">
                                            <Link to={`/blog/${popular.slug}`} className='text-dark text-decoration-none'><Card.Img data-aos="fade-right" data-aos-offset="300" data-aos-duration="1600" src={'https://backend.layadevelopers.com/' + popular.image} className="img-fluid rounded-0" /></Link>
                                        </Col>
                                        <Col xs={8} md={8} lg={7} className="my-auto">
                                            <Card.Body className='p-2' data-aos="fade-right" data-aos-offset="300" data-aos-duration="1600">
                                                <Link to={`/blog/${popular.slug}`} className='text-dark text-decoration-none'><Card.Title className='text-main'><h5 className=' fs-14'>{popular.title.substring(0, 40)}
                                                </h5>
                                                </Card.Title></Link>
                                                <div className='d-flex justify-content-start fs-14'>
                                                    <div className='me-2 my-auto text-sec'><FaClock /></div>
                                                    <div className='my-auto'>{moment(popular.publish_date).format("MMMM D, YYYY")}{" "}</div>
                                                </div>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
                <Row className='g-3 my-5'>
                            <Col xs={12} md={12} lg={12}>
                                <div className="heading-all text-uppercase text-main borNewBott mb-3" data-aos="fade-up" data-aos-duration="2000">
                                    <h4>YOU MAY ALSO LIKE</h4>
                                </div>
                            </Col>
                            {
                                news.map((news, i) => {
                                    return (
                            <Col xs={12} md={6} lg={6} key={news.id}>
                                <Card className='bg-transparent border-0'>

                                    <Row className=''>
                                        <Col xs={12} md={12} lg={12} className="my-auto">
                                            <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'> <Card.Img data-aos="fade-up" data-aos-duration="2500" src={'https://backend.layadevelopers.com/' + news.image} className="img-fluid rounded-0" /></Link>
                                        </Col>
                                        <Col xs={12} md={12} lg={12} className="my-auto">
                                            <Card.Body  className='blogCard' data-aos="fade-up" data-aos-duration="2500">
                                                <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'><Card.Title className='text-main'><h5>{news.title}
                                                </h5>
                                                </Card.Title></Link>
                                                <div className='d-flex justify-content-start mb-3 fs-14'>
                                                    <div className='me-2 my-auto text-sec'><FaClock /></div>
                                                    <div className='my-auto'>{moment(news.publish_date).format("MMMM D, YYYY")}{" "}</div>
                                                </div>
                                                <Card.Text className='fs-14'>
                                                    <div>
                                                    {news.description.replace(/(<([^>]+)>)/gi, " ").substring(1, 100)}
                                                    </div>
                                                </Card.Text>
                                                <div>
                                                    <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'> <button className='btn btn-main'>Read More</button></Link>
                                                </div>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                              )
                            })
                        }
                            
                        </Row>
            </Container>
        </section>
    )
}

