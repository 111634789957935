import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import why1 from '../../Assets/Images/why1.webp'
import why2 from '../../Assets/Images/why2.webp'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import Swiper styles
import 'swiper/css';
// import required modules
import { Pagination, Navigation,Autoplay } from "swiper";
export default class Why extends Component {
    render() {
        return (
            <section className='bgLinear pt-5'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='justify-content-center'>
                                <Col xs={12} md={9} lg={8}>
                                    <div className='heading-all text-uppercase text-center text-white'  data-aos="fade-up" data-aos-duration="2000">
                                        <h1>Why Choose Laya</h1>
                                    </div>
                                    <div className='text-center text-white' data-aos="fade-up" data-aos-duration="2500">
                                        <p className='text-para'>
                                            We endeavour to provide first-class service in every aspect. This includes the procedures and information you'll
                                            need to know at every step of your purchasing journey
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} className="mt-5 mb-0 mb-lg-0 mb-md-5">
                                    <Swiper
                                        spaceBetween={50}
                                        slidesPerView={"auto"}
                                        centeredSlides={false}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: true,
                                          }}
                                        loop={false}
                                        pagination={{
                                            type: "fraction",
                                        }}
                                        navigation={true}
                                        modules={[Pagination, Navigation,Autoplay]}
                                        className="mySwiperWhy"

                                    >
                                        <SwiperSlide>
                                            <div>
                                                <Row>
                                                    <Col xs={12} md={6} lg={6}>
                                                        <img src={why1} className="img-fluid" alt="Laya Developers" />
                                                    </Col>
                                                    <Col xs={12} md={6} lg={5}>
                                                        <div className='text-white  p-3 p-lg-5 p-md-3'>
                                                        
                                                            <h3 className='mb-1'>Customer Centric</h3>
                                                            <p className='text-para mb-0'>
                                                            We understand that buying a house is a big commitment so we aim to always put our customers first and build a long term relationship. We believe in a customer0-first mindset.
                                                            </p>
                                                            
                                                        </div>

                                                    </Col>
                                                </Row>

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <Row>
                                                    <Col xs={12} md={6} lg={6}>
                                                        <img src={why2} className="img-fluid" alt="Laya Developers" />
                                                    </Col>
                                                    <Col xs={12} md={6} lg={5}>
                                                        <div className='text-white  p-3 p-lg-5 p-md-3'>
                                                        
                                                            <h3 className='mb-1'>Modern design-led</h3>
                                                            <p className='text-para mb-0'>
                                                            Our motto is "Where luxury meets affordability" and we believe that exceptional design is key to a great investment. At Laya, we build refined, elegant and intuitively designed apartments with the best quality. 
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <Row>
                                                    <Col xs={12} md={6} lg={6}>
                                                        <img src={why1} className="img-fluid" alt="Laya Developers" />
                                                    </Col>
                                                    <Col xs={12} md={6} lg={5}>
                                                        <div className='text-white  p-3 p-lg-5 p-md-3'>
                                                        
                                                            <h3 className='mb-1'>Timely Completion</h3>
                                                            <p className='text-para mb-0'>
                                                            We work with the best architects, consultants, interior designers, and contractors to ensure that our projects are delivered on time and as communicated. 
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
