
import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Nav, Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import moment from "moment";
import {omit} from 'lodash'
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../../Assets/Images/logoBlack.png';
import gallery1 from '../../Assets/Images/property/heights/LHgallery1.webp'
import gallery2 from '../../Assets/Images/property/heights/LHgallery2.webp'
import gallery3 from '../../Assets/Images/property/heights/LHgallery3.webp'
import gallery4 from '../../Assets/Images/property/heights/LHgallery4.webp'
import gallery5 from '../../Assets/Images/property/heights/LHgallery5.webp'
import gallery6 from '../../Assets/Images/property/heights/LHgallery6.webp'
import gallery7 from '../../Assets/Images/property/heights/LHgallery7.webp'
import gallery8 from '../../Assets/Images/property/heights/LHgallery8.webp'
import gallery9 from '../../Assets/Images/property/heights/LHgallery9.webp'
import gallery10 from '../../Assets/Images/property/heights/LHgallery10.webp'
import whatsapp from '../../Assets/Images/whatsapp.webp';
import phone from '../../Assets/Images/phone-call.webp';
import email from '../../Assets/Images/email.webp';
import visa1 from '../../Assets/Images/visa1.png';
import visa from '../../Assets/Images/visa.png';
import master from '../../Assets/Images/master.png';
import master1 from '../../Assets/Images/master2.png';
import magnati from '../../Assets/Images/magnati.png';
import { EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Autoplay } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
// Import Swiper styles
import 'swiper/css';
import { Link } from 'react-router-dom';

export default function Footer() {
    const [news, setNews] = useState([])
    const [disabled, setdisabled] = useState('')
    const [setting, setSetting] = useState([])
    useEffect(() => {
        async function getLatestNews() {
            try {
                const news = await axios.post("https://backend.layadevelopers.com/api/footerArticle")
                setNews(news.data)
            } catch (error) {
                console.log(error)
            }
        }
        getLatestNews()
        async function getSettings() {
            try {
                const settings = await axios.post("https://backend.layadevelopers.com/api/generalSetting")
                
                setSetting(settings.data)
            } catch (error) {
                console.log(error)
            }
        }
        getSettings();
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#footer-gallery',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, [])
    
    
    const [formVal, setFormVal] = useState({name:'', phone:'', email:'', message:''})
    const [response, setresponse] = useState()
    const [errors, setErrors] = useState()
    const validate = (event, name, value) => {
        //A function to validate each input values

        switch (name) {
           
        
            case 'email':
                if(
                    !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ){
                    setErrors({
                        ...errors,
                        email:'Enter a valid email address'
                    })
                }else{

                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                    
                }
            break;
            
            case 'phone':
                if(
                    !new RegExp(/[0-9+]/g).test(value)
                ){
                    setErrors({
                        ...errors,
                        phone:'Enter Valid Phone Number'
                    })
                }else{

                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                    
                }
            break;
            
            default:
                break;
        }
    }
   
    //A method to handle form inputs
    const handleChange = (event) => {
        //To stop default events    
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        setFormVal({
            ...formVal,
            [name]:val,
        })
        validate(event,name,val);

        //Let's set these values in state
       

    }
      const handleSubmit = (e) => {
        e.preventDefault()
        setdisabled("disabled");
        const user = {
          name: formVal.name,
          phone: formVal.phone,
          email: formVal.email,
          message: formVal.message
        };
        axios.post(`https://backend.layadevelopers.com/api/contactAdd`, {user})
          .then(res => {
            //clearForm();
            //alert(res.data);
            setresponse(res.data);
            
            setTimeout(() =>
                window.location.reload()
            , 2000);
          })
          .catch(errors => {
            setresponse(errors);
        });
    
    }
  return (
    <section>
                <div className='bgGrey p-3 p-lg-5 p-md-3'>
                    <Container>
                        <Row className='text-center g-3'>
                            <Col xs={12} md={12} lg={12}>
                                <div data-aos="fade-up" data-aos-duration="1500"><LinkContainer to="/"><img src={logo} className="img-fluid" width="160" alt="logo" /></LinkContainer></div>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <div data-aos="fade-up" data-aos-duration="2000">
                                    <h5 className='line-before-after'>BUILDING YOUR DREAMS <span className='text-main'>BRICK BY BRICK</span></h5>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <div className='dflexCenter fs-20'>
                                    <div className='d-flex justify-content-start me-4' data-aos="fade-up" data-aos-duration="2500">
                                        <div className='text-main footIcon me-2'><TelephoneFill size={20}></TelephoneFill> </div>
                                        <a className='text-decoration-none text-black' href={"tel:"+setting.phone}>{setting.phone}</a>
                                    </div>
                                    <div className='d-flex justify-content-start' data-aos="fade-up" data-aos-duration="2500">
                                        <div className='text-main footIcon me-2'><EnvelopeFill size={20}></EnvelopeFill> </div>
                                        <a className='text-decoration-none text-black' href={"mailto:"+setting.mail}>{setting.mail}</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Container fluid>
                        <Row className='text-center g-3 py-3'>
                            <Col xs={12} md={12} lg={12}>
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={4}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 40,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    modules={[Autoplay]}
                                    centeredSlides={true}
                                    loop={true}
                                    className="footGallery pswp-gallery"
                                    id='footer-gallery'
                                >
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery1}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery1} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery2}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery2} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery3}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery3} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery4}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery4} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery5}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery5} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery6}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery6} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery7}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery7} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery8}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery8} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery9}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery9} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                        <a href={gallery10}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer">
                                            <img src={gallery10} className="img-fluid" alt="Laya Developers" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='bgMain'>
                    <Container>
                        <Row className=''>
                            <Col xs={12} md={6} lg={3}>
                                <div className='py-5'>
                                    <div className=' footHead text-uppercase mb-4 text-white' data-aos="fade-up" data-aos-duration="1500">
                                        <h4>News & Blogs</h4>
                                    </div>
                                    <Row className='g-4'>
                                    {
                                news.map((news, i) => {
                                    return (
                                        <Col lg={12} key={news.id}>
                                            <Card className='bg-transparent border-0 foot-blog'>
                                                <Row className='g-2'>
                                                    <Col xs={4} md={4} lg={5} className="my-auto">
                                                    <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'> <Card.Img data-aos="fade-up" data-aos-duration="1600" src={'https://backend.layadevelopers.com/' + news.image} className="img-fluid rounded-0" /></Link>
                                                    </Col>
                                                    <Col xs={8} md={8} lg={7} className="my-auto">
                                                        <Card.Body className="p-0" data-aos="fade-up" data-aos-duration="1700">
                                                        <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'><Card.Title className='text-white text-uppercase'><h5>{news.title.substring(0, 18)}</h5>
                                                            </Card.Title></Link>
                                                            <Card.Text className='text-white text-uppercase fs-12'>
                                                                <small>{moment(news.publish_date).format("MMMM D, YYYY")}{" "}</small>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        )
                            })
                        }
                                    </Row>


                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3}>
                                <div className='py-5'>
                                    <div className='footHead text-uppercase mb-4 text-white' data-aos="fade-up" data-aos-duration="1700">
                                        <h4>Quick Links</h4>
                                    </div>
                                    <Row className='g-4'>
                                        <Col lg={12}>
                                            <div id="responsive-navbar-nav">
                                                <ul className="ms-auto footNavLink list-unstyled">
                                                    <li data-aos="fade-up" data-aos-duration="1800"><LinkContainer to="/"><Nav.Link >Home</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="1900"><LinkContainer to="/laya-residences"><Nav.Link>Laya Residences</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="2000"><LinkContainer to="/laya-mansion"><Nav.Link>Laya Mansion</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="2100"><LinkContainer to="/laya-heights"><Nav.Link>Laya Heights</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="2200"><LinkContainer to="/our-brand"><Nav.Link>Our Brand</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="2300"><LinkContainer to="/media"><Nav.Link>News & Blogs</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="2400"> <LinkContainer to="/careers"><Nav.Link>Careers</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="2500"> <LinkContainer to="/contact-Us"><Nav.Link>Contact Us</Nav.Link></LinkContainer></li>
                                                    <li data-aos="fade-up" data-aos-duration="2600"> <LinkContainer to="/become-an-agent"><Nav.Link>Become An Agent</Nav.Link></LinkContainer></li>

                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>


                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <div className='mb-5 p-5 bg-white' data-aos="fade-up" data-aos-duration="2700">
                                    <div>
                                        <p className='text-main text-uppercase'>Enquire</p>
                                    </div>
                                    <Form className='footForm' onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridNme">
                                                <Form.Control onChange={handleChange} value={formVal.name} type="text" name="name" placeholder="Your Name *"  required />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPhone">
                                                <Form.Control  onChange={handleChange} value={formVal.phone} type="text" name="phone" placeholder="Phone Number *" required />
                                                { errors && <span>{errors.phone}</span>  }
                                            </Form.Group>
                                        </Row>

                                        <Form.Group className="mb-3" controlId="formGridEmail">
                                            <Form.Control  onChange={handleChange} value={formVal.email} type="email" name="email" placeholder="Your Email *" required />
                                            { errors && <span>{errors.email}</span>  }
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGridMessage">
                                            <Form.Control  onChange={handleChange} value={formVal.message} as="textarea" name="message" rows={3} placeholder="How can we help you?" />
                                        </Form.Group>


                                        <div>
                                            <Button variant="primary" type="submit" className='btnMain' disabled={disabled}>
                                                Submit
                                            </Button>
                                            { response && <span>{response}</span>  }
                                        </div>

                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        <Row className='pb-4 g-3'>
                            <Col xs={12} md={4} lg={4} className="my-auto">
                                <div className='d-flex justify-content-start py-1'>
                                    <div className='d-flex justify-content-start my-auto'>
                                        <div className='footSocLogo'><a href={setting.facebook}><FaFacebookF></FaFacebookF></a></div>
                                        <div className='footSocLogo'><a href={setting.instagram}><FaInstagram></FaInstagram></a></div>
                                        {/* <div className='footSocLogo'><a href='www.facebook.com/'><FaTwitter></FaTwitter></a></div> */}
                                        <div className='footSocLogo'><a href={setting.linkedin}><FaLinkedinIn></FaLinkedinIn></a></div>
                                    </div>
                                    <div className='d-flex justify-content-start  my-auto linkFoot'>
                                        <div className='linkFoot me-2'><LinkContainer to="/privacy-policy"><Nav.Link >Privacy Policy </Nav.Link></LinkContainer></div>
                                        <div className='linkFoot'><LinkContainer to="/terms-and-conditions"><Nav.Link >Terms & Conditions </Nav.Link></LinkContainer></div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={4} lg={4} className="my-auto">
                                <div className='d-flex justify-content-center py-1'>
                                    <div className='d-flex justify-content-start my-auto'>
                                        <div className='footSocLogo pe-1'><img src={visa} className="img-fluid" width="50" alt="logo" /></div>
                                        <div className='footSocLogo pe-1'><img src={visa1} className="img-fluid" width="50" alt="logo" /></div>
                                        <div className='footSocLogo pe-1'><img src={master} className="img-fluid" width="50" alt="logo" /></div>
                                        <div className='footSocLogo pe-1'><img src={master1} className="img-fluid" width="50" alt="logo" /></div>
                                        <div className='footSocLogo'><img src={magnati} className="img-fluid" width="50" alt="logo" /></div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={4} lg={4} className="my-auto">
                                <div className='d-flex justify-content-end py-1'>
                                    <p className='mb-0 linkFoot text-uppercase fs-12'>© Xpertise Creative Studio 2022. All Rights Reserved.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='social-sticky'>
                    <ul>
                        <li>
                            <a href={"mailto:"+setting.mail}>
                            <img src={email} className="img-fluid" alt="Laya Developers" width={50} />
                            </a>
                        </li>
                        <li>
                            <a href={"tel:"+setting.phone}>
                            <img src={phone} className="img-fluid" alt="Laya Developers" width={50} />
                            </a>
                        </li>
                        <li>
                            <a href={"https://wa.me/"+setting.whatsapp+"?text=Hello there! I have just visited your website and would like to know more information."} target="_blank" rel="noreferrer">
                                <img src={whatsapp} className="img-fluid" alt="Laya Developers" width={50} />
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
  )
}

