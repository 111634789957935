import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import LR1Bed from "../../Assets/Images/property/residence/LR1Bed.webp"
import LR2Bed from "../../Assets/Images/property/residence/LR2Bed.webp"
import floorplan from "../../Assets/Images/property/residence/LRbr.pdf"

export default class Bedrooms extends Component {
    render() {
        return (
            <section className='mb-5'>
                <div>
                <Container fluid className='px-0'>
                    <Row className='g-0'>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-0'>
                                <Col xs={12} md={6} lg={6} className="my-auto">
                                    <div  data-aos="fade-up" data-aos-duration="2000">
                                        <img src={LR1Bed} className="img-fluid  brndImg" alt="Laya Developers" />
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6} className="px-2 px-lg-5 px-md-3 py-4 my-auto">
                                    <div className='text-main text-uppercase '  data-aos="fade-up" data-aos-duration="1500">
                                        <h4>FLOOR PLAN</h4>
                                    </div>
                                    <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-up" data-aos-duration="1700">
                                        <h2>ONE BEDROOM
                                            APARTMENT</h2>
                                    </div>
                                    <div className='mb-4' data-aos="fade-up" data-aos-duration="1900">
                                        <p className='text-para pe-5'>
                                        We understand that families have different preferences so our one bedroom come with an extra room that can be used as a study, a kids bedroom, or anything that you would like it to be. Laya Residences is located in Jumeirah Village Circle which is a family friendly community that has easy access to schools, hospitals, recreation centers and community parks. 
                                        </p>
                                    </div>
                                    <div data-aos="fade-up" data-aos-duration="2100">
                                        <a href={floorplan} rel="noreferrer" download target="_blank" className='text-decoration-none'>
                                            <Button className='btn-mainLgOut'>
                                            <h4 className='mb-0'>DOWNLOAD FLOORPLAN</h4> 
                                            </Button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                </div>
                <div className='bg-lightDark mt-5 '>
                    <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-0'>
                                <Col xs={12} md={6} lg={6} className="px-2 px-lg-5 px-md-3 py-4 my-auto text-white ">
                                        <div className='text-uppercase ' data-aos="fade-up" data-aos-duration="1500">
                                            <h4>FLOOR PLAN</h4>
                                        </div>
                                        <div className='heading-all text-uppercase text-start mb-4'  data-aos="fade-up" data-aos-duration="1700">
                                            <h2>Two BEDROOM
                                                APARTMENT</h2>
                                        </div>
                                        <div className='mb-4' data-aos="fade-up" data-aos-duration="1900">
                                            <p className='text-para pe-5'>
                                            Our two-bedroom apartments are designed to accommodate large family sizes as they are equipped with an extra room. Our two-bedroom apartments are perfect for you if you like holding get-togethers at your home with your friends and family, as the specious layout of the apartment will make them feel comfortable and the ambiance of the home will leave them with a lasting impression.
                                            </p>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="2100">
                                        <a href={floorplan} rel="noreferrer" download target="_blank" className='text-decoration-none'>
                                            <Button className='btn-whiteLgOut'>
                                                <h4 className='mb-0'>DOWNLOAD FLOORPLAN</h4>
                                            </Button>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="my-auto">
                                        <div data-aos="fade-up" data-aos-duration="2000">
                                            <img src={LR2Bed} className="img-fluid  brndImg" alt="Laya Developers" />
                                        </div>
                                    </Col>
                                   
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}
