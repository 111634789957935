import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/SingleBlog/Banner'
import BlogDetail from '../Components/SingleBlog/BlogDetail'

export default function SingleBlog() {
  const [title, setTitle] = useState("");
    const { slug } = useParams();
    useEffect(() => {
        async function getblogDetails() {
            try {
                const blogs = await axios.post("https://backend.layadevelopers.com/api/blog/" + slug
                )
                setTitle(blogs.data[0].title)
                
            } catch (error) {
                console.log(error)
            }
        }
        getblogDetails()
    }, [slug])
  return (
    <main>
      <HelmetProvider><Helmet><title>{title} | Laya Developers</title></Helmet></HelmetProvider>
      
        <Banner />
        <BlogDetail />
    </main>
  )
}
