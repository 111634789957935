import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import courtyard from "../../Assets/Images/LCabout.webp"
export default function About() {
    return (
        <section className='py-5 ' id='height'>
            <div className=' aboutSec'>
                <div className="containerbackground">
                    <h1>LAYA</h1>
                </div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='justify-content-center'>
                                <Col xs={12} md={6} lg={6}>
                                    <div className='heading-all text-uppercase text-start mb-1 mt-5' data-aos="fade-up" data-aos-duration="1200">
                                        <h2>A masterpiece is being built in the heart of Dubai</h2>
                                    </div>
                                    <div className='' data-aos="fade-up" data-aos-duration="1600">
                                        <p>
                                            We thrive towards developing bigger and better projects in the coming years to deliver our promise to our customers. Book your interest in our upcoming project
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6} className="my-auto">
                                    <div className='p-relative mx-0 mx-md-3 mx-lg-5'>
                                        <div className='boxPrimary' data-aos="fade-right" data-aos-duration="1500"></div>
                                        <img src={courtyard} data-aos="fade-right" data-aos-duration="2000" className="img-fluid p-4" alt="Laya Developers" />
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3 justify-content-center mt-3'>

                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='text-uppercase  ' data-aos="fade-up" data-aos-duration="1900"><h4>SLEEK <br></br>APARTMENTS
                                        </h4></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="1500">

                                        <div className='text-uppercase  ' data-aos="fade-up" data-aos-duration="1900"><h4>UNMATCHED <br></br>AMENITIES
                                        </h4></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="1500">

                                        <div className='text-uppercase  ' data-aos="fade-up" data-aos-duration="1900"><h4>AMAZING <br></br>PAYMENT PLANS</h4></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}
