import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import About from '../Components/PayTerms/About'
import Banner from '../Components/PayTerms/Banner'
export default function PayTerms() {
  return (
    <main>
        <HelmetProvider><Helmet><title>Payment Terms and Conditions | Laya Developers</title></Helmet></HelmetProvider>
      <Banner />
      <About />
    </main>
  )
}
