import React, { useEffect, useState } from 'react'
import axios from "axios";
import moment from "moment";
import { Col, Container, Row } from 'react-bootstrap'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";
import { FaClock } from 'react-icons/fa'
import { Link } from 'react-router-dom'
export default function NewsSwiper() {
    const [blogs, setBlog] = useState([])
    useEffect(() => {
        async function getLatestBlog() {
            try {
                const blogs = await axios.post("https://backend.layadevelopers.com/api/latest"
              )
              //console.log(blogs.data);
              setBlog(blogs.data)
            } catch (error) {
                console.log(error)
            }
        }
        getLatestBlog()
    }, [])
  return (
    <section className='my-5' id="news">
    <Container>
        <Row>
            <Col>
                <Row>
                    <Col>
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={10}
                            navigation={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 15,
                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 15,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 15,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 15,
                                },
                            }}
                            modules={[Navigation,Autoplay]}
                            className="newsSwiper"
                        >
                            {
                                blogs.map((news, i) => {
                                    return (
                            <SwiperSlide key={news.id}>
                                <Row className='g-3'>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className='p-relative'>
                                        <Link to={`/blog/${news.slug}`} className='text-white text-decoration-none'>
                                            <img src={'https://backend.layadevelopers.com/' + news.image} className="img-fluid newsSwipGrid1" alt="Laya Developers" />
                                            <div className='newsOverlay'></div>
                                            <div className='blogDetCont text-white'>
                                                <div className='mb-3'>
                                                    <h4>{news.title}</h4>
                                                    <div>{news.description.replace(/(<([^>]+)>)/gi, " ").substring(0, 100)}</div>
                                                </div>
                                                <div className='d-flex justify-content-start text-sec'>
                                                    <div className='me-3'><FaClock /></div>
                                                    <div className=''>{moment(news.publish_date).format("MMMM D, YYYY")}{" "}</div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </SwiperSlide>
                            )
                        })
                    }
                            {/* <SwiperSlide>
                                <Row className='g-3'>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className='p-relative'>
                                        <Link to='/singleBlog' className='text-white text-decoration-none'>  <img src={layaresidence} className="img-fluid newsSwipGrid2" alt="Laya Developers" />
                                            <div className='newsOverlay'></div>
                                            <div className='blogDetCont text-white'>
                                                <div className='mb-2'>
                                                    <h6><b>Donec sed odio dui. Nullam id dolor id nibh
                                                        ultricies vehicula ut id elit.</b></h6>
                                                    <small>Aenean lacinia bibendum nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,         vestibulum at eros...</small>
                                                </div>
                                                <div className='d-flex justify-content-start text-sec'>
                                                    <div className='me-3'><FaClock /></div>
                                                    <div className=''>July 07, 2017</div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className='p-relative'>
                                        <Link to='/singleBlog' className='text-white text-decoration-none'>
                                            <img src={layamansion} className="img-fluid newsSwipGrid2" alt="Laya Developers" />
                                            <div className='newsOverlay'></div>
                                            <div className='blogDetCont text-white'>
                                                <div className='mb-2'>
                                                    <h6><b>Donec sed odio dui. Nullam id dolor id nibh
                                                        ultricies vehicula ut id elit.</b></h6>
                                                    <small>Aenean lacinia bibendum nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,         vestibulum at eros...</small>
                                                </div>
                                                <div className='d-flex justify-content-start text-sec'>
                                                    <div className='me-3'><FaClock /></div>
                                                    <div className=''>July 07, 2017</div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </SwiperSlide> */}
                           
                        </Swiper>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
</section>
  )
}

