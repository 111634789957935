import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import layaresidence from "../../Assets/Images/property/residence/LRabout.webp"
import area from "../../Assets/Images/area.png"
import bed from "../../Assets/Images/bed.png"
import bath from "../../Assets/Images/bath.png"
export default function About() {
    return (
        <section className='py-5' id='residence'>
            <div className=' aboutSec'>
                <div className="containerbackground">
                    <h1>LAYA</h1>
                </div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <div className='heading-all text-uppercase text-start mb-4 mt-5 aos-init aos-animate'  data-aos="fade-right" data-aos-duration="1500">
                                        <h2>COMFORTABLE LIVING THAT
                                            SUITS YOUR LIFESTYLE</h2>
                                    </div>
                                    <div  data-aos="fade-right" data-aos-duration="2000">
                                        <p><b>Discover a home where you can live a life of luxury without
                                            compromising on convenience. </b></p>
                                        <p><b>Ready to Live-in Luxury Residences in Jumeirah Village Circle, Dubai. </b></p>
                                        <p className='text-para'>
                                            Welcome to a living experience that is distinguished by its own style, where you can lead a life of style and elegance. The LAYA residence is a perfect place to be in peace of mind and a comfortable state. With 164 apartment units with a mix of spacious studios, one bedroom, and two bedrooms, each home in LAYA Residences is crafted to perfection without sparing any detail.

                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6} className="my-auto">
                                    <div className='p-relative mx-0 mx-md-3 mx-lg-5'>
                                        <div className='boxPrimary' data-aos="fade-right" data-aos-duration="1500"></div>
                                        <img  data-aos="fade-right" data-aos-duration="2000" src={layaresidence} className="img-fluid p-4" alt="Laya Developers" />
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3'>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3'  data-aos="fade-up" data-aos-duration="1700"><center><img src={area} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '  data-aos="fade-up" data-aos-duration="1900"><h3>LIVING ROOM</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"><p className='text-para pb-2'>Thoughtfully designed with a high ceiling to take care of every element of your life.</p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={bath} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main ' data-aos="fade-up" data-aos-duration="1900"><h3>BATHROOM</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"><p className='text-para pb-2'>Relax in your Spa, a walk-in shower, ambient lighting, and designer accessories.</p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat'ata-aos="fade-up" data-aos-duration="1500">
                                        <div className='mb-3' data-aos="fade-up" data-aos-duration="1700"><center><img src={bed} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main ' data-aos="fade-up" data-aos-duration="1900"><h3>BEDROOM</h3></div>
                                        <div className='borderlight ' data-aos="fade-up" data-aos-duration="2100"><p className='text-para pb-2'>Built-in cove lighting, exquisite double-glazed porcelain flooring, and expansive balconies.</p></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}
