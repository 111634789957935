import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import amenity5 from "../../Assets/Images/amenity/amenity5.webp"
import amenity6 from "../../Assets/Images/amenity/amenity6.webp"
import amenity4 from "../../Assets/Images/amenity/amenity4.webp"
import cctv from "../../Assets/Images/amenity/cctv.png"
import garden from "../../Assets/Images/amenity/garden.png"
import concierge from "../../Assets/Images/amenity/concierge.png"
import retail from "../../Assets/Images/amenity/retail.png"
import pool from "../../Assets/Images/amenity/pool.png"
import school from "../../Assets/Images/amenity/school.png"
export default class Amenities extends Component {
    render() {
        return (
            <section className='mb-5'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                        <Row className='g-3 justify-content-center'>
                                <Col xs={12} md={9} lg={9}>
                                    <div className='heading-all text-uppercase text-center mb-4' data-aos="fade-up" data-aos-duration="1500">
                                        <h2>COME HOME TO AN ARRAY OF
FAMILY-FRIENDLY AMENITIES</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="1700">
                                        <div className='mb-3'><center><img src={amenity5} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>FITNESS CENTER</h5></div>
                                        <div className=' '><p className='text-para pb-2'>Live an active and refreshing
lifestyle at LAYA Mansion, which
has a facilitated gym area and a
dedicated sauna.

                                        </p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="1900">
                                        <div className='mb-3'><center><img src={amenity6} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>JOGGING TRACK
</h5></div>
                                        <div className=' '><p className='text-para pb-2'>At LAYA Mansion, we have
designed jogging track systems for
you to lead a healthy lifestyle.
                                        </p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="2100">
                                        <div className='mb-3'><center><img src={amenity4} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>CHILDREN’S PLAY AREA</h5></div>
                                        <div className=' '><p className='text-para pb-2'>Let your children play safely and
have fun in our indoor playground,
which has a number of activities to
choose from.</p></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3'>
                                <Col xs={12} md={12} lg={12}>
                                    <div className='heading-all text-uppercase text-center mb-4' data-aos="fade-up" data-aos-duration="1500">
                                        <h2>OTHER AMENITIES</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="1700">
                                        <div className='mb-3'><center><img src={school} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase'><h5>CLOSE PROXIMITY
TO SCHOOLS</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="1900">
                                        <div className='mb-3'><center><img src={pool} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase'><h5>INFINITY SWIMMING
                                            POOL</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2100">
                                        <div className='mb-3'><center><img src={retail} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>RETAIL AND
DINING OPTIONS</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2300">
                                        <div className='mb-3'><center><img src={concierge} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>24-HOUR
                                            CONCIERGE</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2500">
                                        <div className='mb-3'><center><img src={garden} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>EVERGREEN
                                            GARDEN</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2700">
                                        <div className='mb-3'><center><img src={cctv} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>24-HOUR
                                            SURVEILLANCE</h5></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
