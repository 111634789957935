import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import mansionLoc from "../../Assets/Images/property/mansion/LMbg3.webp"
import mansionBg2 from "../../Assets/Images/property/mansion/LMbg2.webp"
export default class Location extends Component {
    render() {
        return (
            <section>
                <div className='locatBgGrad mb-5' style={{background: "linear-gradient(to bottom, transparent 60%, #fff 60%), url("+mansionBg2+")"}}>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='bg-white my-5'>
                                <Col xs={12} md={6} lg={6} className="p-1 p-md-3 p-lg-5 my-5">
                                    <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-right" data-aos-duration="1500">
                                        <h2>LOCATION</h2>
                                    </div>
                                    <div data-aos="fade-right" data-aos-duration="2000">
                                        <p className='text-para'>
                                        Centrally located at the Jumeirah Village Circle, LAYA
Mansions is easily accessible from Sheikh Zayed Road and
Al Khail road within 5 minutes’ drive. Located at an equal
distance from Al Maktoum International Airport & Dubai
International Airport makes it very convenient for the
residents of LAYA Mansion to catch up with their air travel.
Besides, some of Dubai’s major landmarks are situated in
close proximity to the property.

                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6} data-aos="fade-right" data-aos-duration="2500" className="" style={{
                                    background: "url(" + mansionLoc + ")", height: "500", backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    width: "100"
                                }}>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <div data-aos="fade-up" data-aos-duration="1900">
                                
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.3554016316816!2d55.19898155055714!3d25.05594058388343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d3bde0f3b8d%3A0x3f2379da55f68106!2sLaya%20Mansion!5e0!3m2!1sen!2sae!4v1673260446994!5m2!1sen!2sae" width="100%" height="500" title='location' style={{ border: "0" }}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </div>
                
            </section>
        )
    }
}
