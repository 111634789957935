import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import pool1 from "../../Assets/Images/amenity/LHpool.webp"
import cinema1 from "../../Assets/Images/amenity/LHcinema.webp"
import padel1 from "../../Assets/Images/amenity/LHpadel.webp"
import gym from "../../Assets/Images/amenity/gym.png"
import track from "../../Assets/Images/amenity/track.png"
import sauna from "../../Assets/Images/amenity/sauna.png"
import garden from "../../Assets/Images/amenity/garden.png"
import pool from "../../Assets/Images/amenity/pool.png"
import bbq from "../../Assets/Images/amenity/bbq.png"
import kitchen from "../../Assets/Images/kitchen.png"
import bed from "../../Assets/Images/bed.png"
import bath from "../../Assets/Images/bath.png"
import slider1 from '../../Assets/Images/sliders/slider-1.webp';
import slider2 from '../../Assets/Images/sliders/slider-2.webp';
import slider3 from '../../Assets/Images/sliders/slider-3.webp';
import slider4 from '../../Assets/Images/sliders/slider-4.webp';
import { Autoplay } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
export default class Amenities extends Component {
    render() {
        return (
            <section className='mb-5'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3 justify-content-center'>
                                <Col xs={12} md={9} lg={9}>
                                    <div className='heading-all text-uppercase text-center' data-aos="fade-up" data-aos-duration="1500">
                                        <h2>World-class interiors </h2>
                                    </div>
                                    <div className='text-center' data-aos="fade-up" data-aos-duration="1700">
                                        <p className='mb-0'>All our apartments are equipped with a smart door lock.</p>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1700">
                                        <div className='mb-3'><center><img src={bed} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>Bedroom </h5></div>
                                        <div className=' '><p className='text-para pb-2'>Designer Oak flooring throughout every bedroom with an exclusive selection of Porcelain tiles & custom Oak High-pressure laminate wardrobes.
                                        </p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="1900">
                                        <div className='mb-3'><center><img src={bath} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>Bathroom
                                        </h5></div>
                                        <div className=' '><p className='text-para pb-2'>Jaguar Branded bathrooms with Custom Built Vanity, Inbuilt shower niches, Inbuilt cove lighting & dark grey walls in the shower area.
                                        </p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center mainFeat' data-aos="fade-up" data-aos-duration="2100">
                                        <div className='mb-3'><center><img src={kitchen} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>Kitchen</h5></div>
                                        <div className=' '><p className='text-para pb-2'>Equipped with quartz countertops, a suite of high-end Midea kitchen appliances that are fully inbuilt and integrated into the cabinetry.</p></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3 justify-content-center'>
                                <Col xs={12} md={9} lg={9}>
                                    <div className='heading-all text-uppercase text-center mb-4' data-aos="fade-up" data-aos-duration="1500">
                                        <h2>COME HOME TO AN ARRAY OF
                                            FAMILY-FRIENDLY AMENITIES</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="1700">
                                        <div className='mb-3'><center><img src={cinema1} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>OUTDOOR CINEMA</h5></div>
                                        <div className=' '><p className='text-para pb-2'>Pick a cozy spot and explore the magic of movies under the
                                            stars, while you stay afloat in the
                                            outdoor pool.

                                        </p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="1900">
                                        <div className='mb-3'><center><img src={pool1} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>RESORT TYPE POOL AREA
                                        </h5></div>
                                        <div className=' '><p className='text-para pb-2'>At LAYA Heights, you won’t have to
                                            leave your home to enjoy a resortlike lifestyle as you can sway along
                                            the pool.
                                        </p></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center' data-aos="fade-up" data-aos-duration="2100">
                                        <div className='mb-3'><center><img src={padel1} className="img-fluid" alt="Laya Developers" /></center></div>
                                        <div className='text-uppercase text-main '><h5>PADEL TENNIS COURT</h5></div>
                                        <div className=' '><p className='text-para pb-2'>Head to the state-of-the-art turf at LAYA Heights Padel Tennis Court and move your body to the rhythm of the sport.</p></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3'>
                                <Col xs={12} md={12} lg={12}>
                                    <div className='heading-all text-uppercase text-center mb-4' data-aos="fade-up" data-aos-duration="1500">
                                        <h2>OTHER AMENITIES</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="1700">
                                        <div className='mb-3'><center><img src={track} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase'><h5>JOGGING TRACK</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="1900">
                                        <div className='mb-3'><center><img src={pool} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase'><h5>KIDS POOL</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2100">
                                        <div className='mb-3'><center><img src={bbq} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>BBQ/PICNIC AREA</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2300">
                                        <div className='mb-3'><center><img src={gym} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>INDOORS AND
                                            OUTDOOR GYM</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2500">
                                        <div className='mb-3'><center><img src={garden} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>CHILDREN'S PLAY AREA</h5></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className='p-4 text-center ameniMain' data-aos="fade-up" data-aos-duration="2700">
                                        <div className='mb-3'><center><img src={sauna} className="img-fluid" alt="Laya Developers" width={100} /></center></div>
                                        <div className='text-uppercase '><h5>SAUNA</h5></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='g-3'>
                                <Col xs={12} md={12} lg={12}>
                                    <div className='heading-all text-uppercase text-center mt-3' data-aos="fade-up" data-aos-duration="1500">
                                        <h2>Construction Status</h2>
                                    </div>
                                    <div className='text-center' data-aos="fade-up" data-aos-duration="1700">
                                        <p className='mb-0'>The project is 90% completed, with interior finishing’s underway.</p>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12}>
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={4}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 40,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    modules={[Autoplay]}
                                    centeredSlides={true}
                                    loop={true}
                                    className="constructionGallery"

                                >
                                    <SwiperSlide>
                                        <div>
                                            <img src={slider1} className="img-fluid" alt="Laya Developers" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <img src={slider2} className="img-fluid" alt="Laya Developers" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <img src={slider3} className="img-fluid" alt="Laya Developers" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <img src={slider4} className="img-fluid" alt="Laya Developers" />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
