import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Col, Container, Row, Card } from 'react-bootstrap'
import { FaClock } from 'react-icons/fa';
import moment from "moment";
import { Link } from 'react-router-dom';
export default function BlogDetail() {
    
    const [news, setNews] = useState([])
    useEffect(() => {
        async function getLatestNews() {
            try {
                const news = await axios.post("https://backend.layadevelopers.com/api/allBlogs")
                setNews(news.data)
            } catch (error) {
                console.log(error)
            }
        }
        getLatestNews()
    }, [])

    return (
        <section className='aos-init aos-animate' id="blogs">
           

            <Container className='my-5'>
                
                <Row className='g-3 my-5'>
                            <Col xs={12} md={12} lg={12}>
                                <div className="heading-all text-uppercase text-main borNewBott mb-3" data-aos="fade-up" data-aos-duration="2000">
                                    <h4>Our Blogs</h4>
                                </div>
                            </Col>
                            {
                                news.map((news, i) => {
                                    return (
                            <Col xs={12} md={4} lg={4} key={news.id}>
                                <Card className='bg-transparent border-0'>

                                    <Row className=''>
                                        <Col xs={12} md={12} lg={12} className="my-auto">
                                            <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'> <Card.Img data-aos="fade-up" data-aos-duration="2500" src={'https://backend.layadevelopers.com/' + news.image} className="img-fluid rounded-0" /></Link>
                                        </Col>
                                        <Col xs={12} md={12} lg={12} className="my-auto">
                                            <Card.Body  className='blogCard' data-aos="fade-up" data-aos-duration="2500">
                                                <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'><Card.Title className='text-main'><h5>{news.title.substring(0, 50)}
                                                </h5>
                                                </Card.Title></Link>
                                                <div className='d-flex justify-content-start mb-3 fs-14'>
                                                    <div className='me-2 my-auto text-sec'><FaClock /></div>
                                                    <div className='my-auto'>{moment(news.publish_date).format("MMMM D, YYYY")}{" "}</div>
                                                </div>
                                                <Card.Text className='fs-14'>
                                                    <div>
                                                    {news.description.replace(/(<([^>]+)>)/gi, " ").substring(0, 100)}
                                                    </div>
                                                </Card.Text>
                                                <div>
                                                    <Link to={`/blog/${news.slug}`} className='text-dark text-decoration-none'> <button className='btn btn-main'>Read More</button></Link>
                                                </div>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                              )
                            })
                        }
                            
                        </Row>
            </Container>
        </section>
    )
}

