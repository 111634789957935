import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

import About from '../Components/Home/About'
import Banner from '../Components/Home/Banner'
import Benefits from '../Components/Home/Benefits'
import Community from '../Components/Home/Community'
import Numbers from '../Components/Home/Numbers'
import Project from '../Components/Home/Project'
import Testimonial from '../Components/Home/Testimonial'
import Why from '../Components/Home/Why'

export default function Home() {
 
  return (
    <main>
      <HelmetProvider>
      <Helmet>
                <title>Home | Laya Developers</title>
            </Helmet>
            </HelmetProvider>
      <Banner />
      <About />
      <Project />
      <Community />
      <Why />
      <Numbers />
      <Benefits />
      <Testimonial />
    </main>
  )
}
