import React, { Component } from 'react'
import { Col, Container, Row, Accordion } from 'react-bootstrap'
import benefit from '../../Assets/Images/benefit.webp'
export default class Benefits extends Component {
    render() {
        return (
            <section className='bgGrey py-5'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='justify-content-center'>
                                <Col xs={12} md={7} lg={6}>
                                    <div className='heading-all text-uppercase text-center'  data-aos="fade-up" data-aos-duration="2000">
                                        <h1>Benefits of investing in Dubai Real Estate </h1>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row className='my-5 bg-white'>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='justify-content-center'>
                                <Col xs={12} md={6} lg={6}>
                                    <div className=' p-2 p-lg-5 p-md-1'  data-aos="fade-right" data-aos-duration="2000">
                                        <Accordion defaultActiveKey="0" className='accordBenefit'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Tax-free income </Accordion.Header>
                                                <Accordion.Body>
                                                Investment in Dubai real estate will never tick you with annual property tax, income tax, capital gains tax, or rental revenue tax. You are open to doing big or bigger investments to uplift your financial portfolio and choose from some of the best real estate projects in Dubai offering high rental yields and capital gains.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Safety </Accordion.Header>
                                                <Accordion.Body>
                                                Not only the best place to live in but also the safest country for your families due to its low crime rate and violence. You can walk, drive, or study without any fear.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>High standard of living</Accordion.Header>
                                                <Accordion.Body>
                                                Dubai is renowned for its opulent lifestyle with spacious residences and luxurious living standards, that come with extra-ordinary amenities. People from around the world come to Dubai to experience a truly luxurious lifestyle that cannot be found in any part of the world.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>World-class infrastructure</Accordion.Header>
                                                <Accordion.Body>
                                                Dubai, along with other emirates, has established itself as the principal business hub in the middle east because to its massive infrastructure. Here are some key infrastructural projects and urban planning features that makes this city an amazing place to live in.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Economic growth and stability</Accordion.Header>
                                                <Accordion.Body>
                                                Dubai's affluent economy can have you convinced to choose this city for your investment opportunities due to its infrastructure and security.Today, UAE is one of the few nations in the world with a highly diversified economy.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6} className="mapImagCont" style={{ background: `url(${benefit})`, minHeight:600 }}>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Container>
            </section>
        )
    }
}
