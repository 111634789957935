import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import LM1Bed from "../../Assets/Images/property/mansion/LM1bhk.webp"
import LM2Bed from "../../Assets/Images/property/mansion/LM2bhk.webp"
import studio from "../../Assets/Images/property/mansion/LMstudio.webp"
import LM2fp from "../../Assets/Images/property/mansion/LM2bh.pdf"
import LMstFp from "../../Assets/Images/property/mansion/LMstudio.pdf"
import LM1fp from "../../Assets/Images/property/mansion/LM1bh.pdf"

export default class Bedrooms extends Component {
    render() {
        return (
            <section className='mb-5'>
                <div>
                    <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-0'>
                                    <Col xs={12} md={6} lg={6} className="my-auto">
                                        <div data-aos="fade-up" data-aos-duration="2000">
                                            <img src={studio} className="img-fluid  brndImg" alt="Laya Developers" />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="px-2 px-lg-5 px-md-3 py-4 my-auto">
                                        <div className='text-main text-uppercase ' data-aos="fade-up" data-aos-duration="1500">
                                            <h4>FLOOR PLAN</h4>
                                        </div>
                                        <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-up" data-aos-duration="1700">
                                            <h2>STUDIO
                                                APARTMENT</h2>
                                        </div>
                                        <div className='mb-4' data-aos="fade-up" data-aos-duration="1900">
                                            <p className='text-para pe-5'>
                                                Choose the completely furnished studio apartments at Laya Mansion instead of the burden of picking out items for each area of your home, which are expertly and thoughtfully designed for an upgraded living experience.
                                            </p>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="2100">
                                            <a href={LMstFp} rel="noreferrer" download target="_blank" className='text-decoration-none'>
                                                <Button className='btn-mainLgOut'>
                                                    <h4 className='mb-0'>DOWNLOAD FLOORPLAN</h4>
                                                </Button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='bg-lightDark my-5 '>
                    <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-0'>
                                    <Col xs={12} md={6} lg={6} className="px-2 px-lg-5 px-md-3 py-4 my-auto text-white ">
                                        <div className='text-uppercase ' data-aos="fade-up" data-aos-duration="1500">
                                            <h4>FLOOR PLAN</h4>
                                        </div>
                                        <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-up" data-aos-duration="1700">
                                            <h2>One BEDROOM
                                                APARTMENT</h2>
                                        </div>
                                        <div className='mb-4' data-aos="fade-up" data-aos-duration="1900">
                                            <p className='text-para pe-5'>
                                                Our one-bedroom apartments come with an additional room that may be used as a study, a child's bedroom, or anything else you would like it to be because we are aware that families have a variety of tastes.
                                            </p>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="2100">
                                            <a href={LM1fp} rel="noreferrer" download target="_blank" className='text-decoration-none'>
                                                <Button className='btn-whiteLgOut'>
                                                    <h4 className='mb-0'>DOWNLOAD FLOORPLAN</h4>
                                                </Button>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="my-auto">
                                        <div data-aos="fade-up" data-aos-duration="2000">
                                            <img src={LM1Bed} className="img-fluid  brndImg" alt="Laya Developers" />
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-0'>
                                    <Col xs={12} md={6} lg={6} className="my-auto">
                                        <div data-aos="fade-up" data-aos-duration="2000">
                                            <img src={LM2Bed} className="img-fluid  brndImg" alt="Laya Developers" />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="px-2 px-lg-5 px-md-3 py-4 my-auto">
                                        <div className='text-main text-uppercase ' data-aos="fade-up" data-aos-duration="1500">
                                            <h4>FLOOR PLAN</h4>
                                        </div>
                                        <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-up" data-aos-duration="1700">
                                            <h2>TWO Bedroom apartment</h2>
                                        </div>
                                        <div className='mb-4' data-aos="fade-up" data-aos-duration="1900">
                                            <p className='text-para pe-5'>
                                                Our two-bedroom apartments, which have an additional room and overlook the pool area, are made to suit large family sizes.
                                            </p>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="2100">
                                            <a href={LM2fp} rel="noreferrer" download target="_blank" className='text-decoration-none'>
                                                <Button className='btn-mainLgOut'>
                                                    <h4 className='mb-0'>DOWNLOAD FLOORPLAN</h4>
                                                </Button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}
