import React, { useState } from 'react'
import axios from "axios";
import { Container, Row, Col,  Button, Form } from 'react-bootstrap';
import { omit } from 'lodash';

export default function Subscribe() {
    const [formVal, setFormVal] = useState({ email: '' })
    const [response, setresponse] = useState()
    const [disabled, setdisabled] = useState('')
    const [errors, setErrors] = useState()
    const validate = (event, name, value) => {
        //A function to validate each input values

        switch (name) {


            case 'email':
                if (
                    !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        email: 'Enter a valid email address'
                    })
                } else {

                    let newObj = omit(errors, "email");
                    setErrors(newObj);

                }
                break;

            default:
                break;
        }
    }

    //A method to handle form inputs
    const handleChange = (event) => {
        //To stop default events    
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        setFormVal({
            ...formVal,
            [name]: val,
        })
        validate(event, name, val);

        //Let's set these values in state


    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setdisabled("disabled");
        const user = {
            email: formVal.email
        };
        axios.post(`https://backend.layadevelopers.com/api/subscribeAdd`, { user })
            .then(res => {
                //clearForm();
                //console.log(res.data);
                setresponse(res.data);

                setTimeout(() =>
                    window.location.reload()
                    , 2000);
            })
            .catch(errors => {
                setresponse(errors);
            });

    }
    return (
        <section>

            <div className='bgMain'>
                <Container>
                    <Row className='justify-content-center'>

                        <Col xs={12} md={12} lg={6} className="my-auto">
                            <div className='p-5 bg-white' data-aos="fade-up" data-aos-duration="2700">
                                <div>
                                    <p className='text-main text-uppercase'>Enquire Now</p>
                                </div>
                                <Form className='subscibeForm' onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col xs={8} md={8} lg={8} className="my-auto">
                                            <Form.Group className="mb-0" controlId="formGridEmail">
                                                <Form.Control onChange={handleChange} value={formVal.email} type="email" name="email" placeholder="Your Email *" required />
                                                {errors && <span>{errors.email}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={4} lg={4} className="my-auto">
                                            <div>
                                                <Button variant="primary" type="submit" className='btnMain' disabled={disabled}>
                                                    Enquire
                                                </Button>
                                                
                                            </div>
                                        </Col>
                                        <Col lg={12}>{response && <span>{response}</span>}</Col>
                                    </Row>


                                </Form>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>

        </section>
    )
}
