import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/Error/Banner'

export default function Error() {
  return (
    <main>
      <HelmetProvider>
      <Helmet>
        <title>404 Error | Laya Developers</title>
      </Helmet>
      </HelmetProvider>
      <Banner />
    </main>
  )
}
