import React, { Component } from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import banner1 from "../../Assets/Images/banner/bannerVideo.mov"
import banner2 from "../../Assets/Images/banner/bannerVideo.mp4"
import banner6 from "../../Assets/Images/banner/bannerVideo.webm"
import banner3 from "../../Assets/Images/banner/residenceBg.webp"
import banner4 from "../../Assets/Images/banner/heightBg.webp"
import banner5 from "../../Assets/Images/banner/homeBg.webp"

export default class Banner extends Component {

    render() {

        return (
            <section>
                <Container fluid className='px-0'>
                    <Row className='g-0 '>
                        <Col xs={12} md={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
                            <Carousel className='bannerCarousel'>
                                <Carousel.Item interval={2000}>
                                    <video className="d-block w-100 videoMain" autoPlay loop playsinline preload="metadata" muted poster={banner3}>
                                    
                                    <source src={banner2}
                                            type="video/mp4"></source>
                                        <source src={banner1}
                                            type="video/mov"></source>
                                            <source src={banner6}
                                            type="video/webm"></source>
                                        
                                        Sorry, your browser doesn't support videos.
                                    </video>
                                    <div className='videoOverlay'></div>
                                    <Container className='centered'>
                                        <Row>
                                            <Col>
                                                <div data-aos="fade-up" data-aos-duration="1000">
                                                    <h1 className='main-heading'>Welcome to Laya Developers</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Carousel.Caption>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>scroll
                                            </a>
                                        </div>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>
                                                <span className='line' ></span>
                                                <div className='arrowSpan mouse-croll'>
                                                    <div className="down-arrow-1"></div>
                                                    <div className="down-arrow-2"></div>
                                                </div>
                                            </a>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                
                            </Carousel>
                        </Col>
                        <Col xs={12} md={12} lg={12} className="d-block d-md-none d-lg-none d-xl-none">
                            <Carousel className='bannerCarousel'>
                                <Carousel.Item interval={2000}>
                                    
                                    <img className="d-block w-100" src={banner3} alt="First slide" /> 
                                     <Container className='centered'>
                                        <Row className='justify-content-center'>
                                            <Col xs={12} md={11} lg={10}>
                                                <div data-aos="fade-up" data-aos-duration="1000">
                                                    <h1 className='main-heading'>Live the luxurious lifestyle you have always dreamt about</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Carousel.Caption>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>scroll
                                            </a>
                                        </div>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>
                                                <span className='line' ></span>
                                                <div className='arrowSpan mouse-croll'>
                                                    <div className="down-arrow-1"></div>
                                                    <div className="down-arrow-2"></div>
                                                </div>
                                            </a>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item interval={2000}>
                                    <img className="d-block w-100" src={banner4} alt="First slide" />
                                    <Container className='centered'>
                                        <Row>
                                            <Col>
                                                <div data-aos="fade-up" data-aos-duration="1000">
                                                    <h1 className='main-heading'>Crafted with convenience and modernity</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Carousel.Caption>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>scroll <span className='vertical'></span></a>
                                        </div>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>
                                                <span className='line' ></span>
                                                <div className='arrowSpan mouse-croll'>
                                                    <div className="down-arrow-1"></div>
                                                    <div className="down-arrow-2"></div>
                                                </div>
                                            </a>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item interval={2000}>
                                    <img className="d-block w-100" src={banner5} alt="First slide" />
                                    <Container className='centered'>
                                        <Row>
                                            <Col>
                                                <div data-aos="fade-up" data-aos-duration="1000">
                                                    <h1 className='main-heading'>Your quest for high-end living ends here</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Carousel.Caption>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>scroll <span className='vertical'></span></a>
                                        </div>
                                        <div className='scrollDiv'>
                                            <a href='#about' className='text-decoration-none text-white'>
                                                <span className='line' ></span>
                                                <div className='arrowSpan mouse-croll'>
                                                    <div className="down-arrow-1"></div>
                                                    <div className="down-arrow-2"></div>
                                                </div>
                                            </a>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
