import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/Partnerships/Banner'
import Brands from '../Components/Partnerships/Brands'
import Logos from '../Components/Partnerships/Logos'

export default function Partnership() {
 
  return (
    <main>
      <HelmetProvider><Helmet><title>Our Partnership | Laya Developers</title></Helmet></HelmetProvider>
        <Banner />
        <Brands />
        <Logos />
    </main>
  )
}
