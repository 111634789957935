import React, { useRef, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import gallery1 from '../../Assets/Images/property/mansion/LMgallery1.webp'
import gallery2 from '../../Assets/Images/property/mansion/LMgallery2.webp'
import gallery3 from '../../Assets/Images/property/mansion/LMgallery3.webp'
import gallery4 from '../../Assets/Images/property/mansion/LMgallery4.webp'
import gallery5 from '../../Assets/Images/property/mansion/LMgallery5.webp'
import gallery6 from '../../Assets/Images/property/mansion/LMgallery6.webp'
import brochure from "../../Assets/Images/property/documents/laya-mansion-brochure-fp.pdf"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
const ManipulatingComponentOutSideSwiper = () => {
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#my-gallery',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);
    const swiperRef = useRef(null);
    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <section>
            <div className='bgDarkLinear my-5'>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={{ span: 6, offset: 6 }} lg={{ span: 6, offset: 6 }} className="pt-5">
                            <div className='text-center d-flex justify-content-center'>
                                <div className="custom-pagination me-3 my-auto d-flex justify-content-start"></div>
                                <div className='text-center d-flex justify-content-center my-auto'>
                                    <button className='buttonGall' onClick={goPrev}><FaChevronLeft /></button>
                                    <button className='buttonGall'  onClick={goNext}><FaChevronRight /></button>
                                </div>
                            </div>

                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='justify-content-center'>

                            <Col xs={12} md={12} lg={12} className="mt-5">
                                    <Swiper ref={swiperRef}
                                        effect={"coverflow"}
                                        grabCursor={true}
                                        centeredSlides={true}
                                        loop={true}
                                        slidesPerView={3}
                                        spaceBetween={40}

                                        coverflowEffect={{
                                            //rotate: 50,
                                            stretch: 0,
                                            depth: 100,
                                            modifier: 1,
                                            slideShadows: true,
                                        }}

                                        pagination={{
                                            el: '.custom-pagination',
                                            clickable: true,
                                            type: "fraction",
                                            formatFractionCurrent: function (number) {
                                                return ('0' + number).slice(-2);
                                            },
                                            formatFractionTotal: function (number) {
                                                return ('0' + number).slice(-2);
                                            },
                                            renderFraction: function (currentClass, totalClass) {
                                                return '<div class="my-auto ' + currentClass + '"></div>' +
                                                    ' <div class=" mx-2 my-auto  lineHr"></div>' +
                                                    '<div class="my-auto ' + totalClass + '"></div>';
                                            }
                                        }}
                                        navigation={{
                                            nextEl: '.swiper-button-nextNew',
                                            prevEl: '.swiper-button-prevNew',
                                        }}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            767: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 40,
                                            },
                                            1200: {
                                                slidesPerView: 3,
                                                spaceBetween: 40,
                                            },
                                        }}
                                        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                                        className="swipGallery pswp-gallery"
                                        id='my-gallery'
                                    >
                                        <SwiperSlide>
                                            <div> <a href={gallery1}
                                                data-pswp-width="1080"
                                                data-pswp-height="1080" target="_blank" rel="noreferrer"><img src={gallery1} className='img-fluid' alt='Laya Residences' /> </a> </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <a href={gallery2}
                                                    data-pswp-width="1080"
                                                    data-pswp-height="1080" target="_blank" rel="noreferrer">
                                                    <img src={gallery2} className='img-fluid' alt='Laya Residences' />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <a href={gallery3}
                                                    data-pswp-width="1080"
                                                    data-pswp-height="1080" target="_blank" rel="noreferrer">
                                                    <img src={gallery3} className='img-fluid' alt='Laya Residences' />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <a href={gallery4}
                                                    data-pswp-width="1080"
                                                    data-pswp-height="1080" target="_blank" rel="noreferrer">
                                                    <img src={gallery4} className='img-fluid' alt='Laya Residences' />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <a href={gallery5}
                                                    data-pswp-width="1080"
                                                    data-pswp-height="1080" target="_blank" rel="noreferrer">
                                                    <img src={gallery5} className='img-fluid' alt='Laya Residences' />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <a href={gallery6}
                                                    data-pswp-width="1080"
                                                    data-pswp-height="1080" target="_blank" rel="noreferrer">
                                                    <img src={gallery6} className='img-fluid' alt='Laya Residences' />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='locatBgGradLeft'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row className='py-5'>
                                <Col xs={12} md={6} lg={6} className="">
                                    <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-right" data-aos-duration="1500">
                                        <h2>DOWNLOAD OUR BROCHURE FOR
                                            MORE DETAILS ABOUT YOUR DREAM
                                            HOME AT LAYA MANSION.</h2>
                                    </div>
                                    <div data-aos="fade-right" data-aos-duration="2000">
                                    <a href={brochure} rel="noreferrer" download target="_blank" className='text-decoration-none'>
                                        <Button className='btn-dark'><h4 className='mb-0'>DOWNLOAD BROCHURE</h4></Button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
};
export default ManipulatingComponentOutSideSwiper;
