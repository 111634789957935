import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import studio from "../../Assets/Images/property/heights/LHstudio.webp"
import LH1bhk from "../../Assets/Images/property/heights/LH1bhk.webp"

import floorplan1 from "../../Assets/Images/property/heights/LH1br.pdf"
import floorplan2 from "../../Assets/Images/property/heights/LHstudio.pdf"
export default class Bedrooms extends Component {
    render() {
        return (
            <section className='mb-5'>
                <div>
                    <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-0'>
                                    <Col xs={12} md={6} lg={6} className="my-auto">
                                        <div data-aos="fade-up" data-aos-duration="2000">
                                            <img src={studio} className="img-fluid brndImg" alt="Laya Developers" />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="px-2 px-lg-5 px-md-3 py-4 my-auto">
                                        <div className='text-main text-uppercase ' data-aos="fade-up" data-aos-duration="1500">
                                            <h4>FLOOR PLAN</h4>
                                        </div>
                                        <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-up" data-aos-duration="1700">
                                            <h2>STUDIO
                                                APARTMENT</h2>
                                        </div>
                                        <div className='mb-4' data-aos="fade-up" data-aos-duration="1900">
                                            <p className='text-para pe-5'>
                                                Choose the completely furnished studio apartments at Laya Heights instead of the burden of picking out items for each area of your home, which are expertly and thoughtfully designed for an upgraded living experience.
                                            </p>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="2100">
                                            <a href={floorplan2} target="_blank" rel="noreferrer" download className='text-decoration-none'>
                                                <Button className='btn-mainLgOut'>
                                                    <h4 className='mb-0'>DOWNLOAD FLOORPLAN</h4>
                                                </Button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='bg-lightDark my-5 '>
                    <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col xs={12} md={12} lg={12}>
                                <Row className='g-0'>
                                    <Col xs={12} md={6} lg={6} className="px-2 px-lg-5 px-md-3 py-4 my-auto text-white ">
                                        <div className='text-uppercase ' data-aos="fade-up" data-aos-duration="1500">
                                            <h4>FLOOR PLAN</h4>
                                        </div>
                                        <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-up" data-aos-duration="1700">
                                            <h2>One BEDROOM
                                                APARTMENT</h2>
                                        </div>
                                        <div className='mb-4' data-aos="fade-up" data-aos-duration="1900">
                                            <p className='text-para pe-5'>
                                                We understand that families have different preferences so our one bedroom come with an extra room that can be used as a study, a kid’s bedroom, or anything that you would like it to be. Laya Heights is located in Dubai Studio City which is a family friendly community that has easy access to schools, hospitals, recreation centers and community parks.
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between" data-aos="fade-up" data-aos-duration="2100">
                                            <div>
                                                <a href={floorplan1} target="_blank" download rel="noreferrer" className='text-decoration-none'>
                                                    <Button className='btn-whiteLgOut'>
                                                        <h4 className='mb-0'>DOWNLOAD FLOORPLAN</h4>
                                                    </Button>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="https://renderatelier.com/360/Akshara" target="_blank" rel="noreferrer" className='text-decoration-none'>
                                                    <Button className='btn-whiteLgOut'>
                                                        <h4 className='mb-0'>VIEW Virtual Tour</h4>
                                                    </Button>
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="my-auto">
                                        <div data-aos="fade-up" data-aos-duration="2000">
                                            <img src={LH1bhk} className="img-fluid brndImg" alt="Laya Developers" />

                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}
