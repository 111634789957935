import React, { Component } from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import banner1 from "../../Assets/Images/property/heightBg.webp"

export default class Banner extends Component {
    render() {
        return (
            <section>
                <Container fluid className='px-0'>
                    <Row className='g-0'>
                        <Col xs={12} md={12} lg={12}>
                            <Carousel className='bannerCarousel'>
                                <Carousel.Item  interval={3000}>
                                    <img className="d-block w-100" src={banner1} alt="First slide" />
                                    <Container className='centered'>
                                        <Row className='justify-content-center text-center text-white'>
                                            <Col xs={12} md={6} lg={6}>
                                                <div>
                                                    <h1 className='main-heading'>404 Page Not Found</h1>
                                                </div>
                                                <div>
                                                <div>
                                        <Link to={"/"} className='text-decoration-none text-white'> <p className='text-decoration-none text-white'> Back To Home &nbsp;<span>&#10230;</span></p></Link>
                                        </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
