import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { useParams } from 'react-router-dom';

export default function Banner() {
    const [title, setTitle] = useState();
    const [image, setImage] = useState();
    const { slug } = useParams();
    useEffect(() => {
        async function getblogDetails() {
            try {
                const blogs = await axios.post("https://backend.layadevelopers.com/api/blog/" + slug
                )
                setTitle(blogs.data[0].title)
                setImage('https://backend.layadevelopers.com/'+blogs.data[0].image)

            } catch (error) {
                console.log(error)
            }
        }
        getblogDetails()
    }, [slug])
  return (
    <section>
    <Container fluid className='px-0'>
        <Row className='g-0'>
            <Col xs={12} md={12} lg={12}>
                <Carousel className='newsDetails bannerCarousel'>
                    <Carousel.Item  interval={3000}>
                        <img className="d-block w-100" src={image} alt="First slide" />
                        <div className='bgOverlay'></div>
                        <Container className='centered'>
                            <Row className='justify-content-center'>
                                <Col xs={12} md={6} lg={6}>
                                    <div data-aos="fade-up" data-aos-duration="1500">
                                        <h1 className='main-heading'>NEWS & BLOGS</h1>
                                    </div>
                                    <div data-aos="fade-up" data-aos-duration="2000">
                                        <p className='mainPara'>{title}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Carousel.Caption>
                            <div className='scrollDiv'>
                                <a href='#location' className='text-decoration-none text-white'>scroll 
                                 </a>
                            </div>
                            <div className='scrollDiv'>
                                <a href='#location' className='text-decoration-none text-white'>
                                    <span className='line' ></span>
                                    <div className='arrowSpan mouse-croll'>
                                        <div className="down-arrow-1"></div>
                                        <div className="down-arrow-2"></div>
                                    </div>
                                </a>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    </Container>
</section>
  )
}
