import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import About from '../Components/Residence/About'
import Amenities from '../Components/Residence/Amenities'
import Banner from '../Components/Residence/Banner'
import Bedrooms from '../Components/Residence/Bedrooms'
import Gallery from '../Components/Residence/Gallery'
import Location from '../Components/Residence/Location'
export default function Residence() {
 
  return (
    <main>
      <HelmetProvider><Helmet><title>Laya Residences | Laya Developers</title></Helmet></HelmetProvider>
        <Banner />
        <About />
        <Bedrooms />
        <Amenities />
        <Location />
        <Gallery/>
    </main>
  )
}
