import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/Blogs/Banner'
import Latest from '../Components/Blogs/Latest'
import NewsSwiper from '../Components/Blogs/NewsSwiper'

export default function Blogs() {
 
  return (
    <main>
      <HelmetProvider>
      <Helmet>
                <title>Media | Laya Developers</title>
            </Helmet>
            </HelmetProvider>
      <Banner />
      <NewsSwiper />
      <Latest />
    </main>
  )
}
