import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { FaMapMarkerAlt } from 'react-icons/fa'
import banner1 from "../../Assets/Images/property/heights/LHbg.webp"
export default function Banner() {
    const [countdownDate, setCountdownDate] = useState(new Date('08/31/2023').getTime());
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        setInterval(() => setNewTime(), 1000);
    }, []);

    const setNewTime = () => {
        if (countdownDate) {
            const currentTime = new Date().getTime();

            const distanceToDate = countdownDate - currentTime;

            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            );
            let minutes = Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
            );
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

            days = `${days}`;
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`;
            } else if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`;
            } else if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`;
            }

            setState({ days: days, hours: hours, minutes, seconds });
        }
    };
    return (
        <section>
            <Container fluid className='px-0'>
                <Row className='g-0'>
                    <Col xs={12} md={12} lg={12}>
                        <Carousel className='bannerCarousel'>
                            <Carousel.Item interval={3000}>
                                <img className="d-block w-100" src={banner1} alt="First slide" />
                                <Container className='centered'>
                                    <Row className='justify-content-center text-center text-white'>
                                        <Col xs={12} md={12} lg={12}>
                                            <div data-aos="fade-up" data-aos-duration="1500">
                                                <h1 className='main-heading mb-0'>LAYA HEIGHTS</h1>
                                            </div>
                                            <div data-aos="fade-up" data-aos-duration="1700">
                                                <h5><FaMapMarkerAlt /> DUBAI STUDIO CITY</h5>
                                            </div>
                                            <div data-aos="fade-up" data-aos-duration="1900">
                                                    <p className='mb-1'>Studio & 1 Bedroom Apartments</p>
                                                </div>
                                            
                                        </Col>
                                        <Col xs={12} md={12} lg={5}>
                                            
                                            <div className='projDet' data-aos="fade-up" data-aos-duration="2100">
                                                <div className='text-black'>
                                                    <h5 className='mb-0'>Handed over in January 2024</h5>
                                                </div>
                                            </div>
                                            {/* <div className='countdown-wrapper d-flex justify-content-evenly my-3'>
                                                <div className='time-section'>
                                                    <div className='time'>{state.days || '0'}</div>
                                                    <small className="time-text">Days</small>
                                                </div>
                                                <div className='time-section'>
                                                    <div className='time'>:</div>
                                                </div>
                                                <div className='time-section'>
                                                    <div className='time'>{state.hours || '00'}</div>
                                                    <small className="time-text">Hours</small>
                                                </div>
                                                <div className='time-section'>
                                                    <div className='time'>:</div>
                                                </div>
                                                <div className='time-section'>
                                                    <div className='time'>{state.minutes || '00'}</div>
                                                    <small className="time-text">Minutes</small>
                                                </div>
                                                <div className='time-section'>
                                                    <div className='time'>:</div>
                                                </div>
                                                <div className='time-section'>
                                                    <div className='time'>{state.seconds || '00'}</div>
                                                    <small className="time-text">Seconds</small>
                                                </div>
                                            </div> */}
                                        </Col>
                                    </Row>
                                </Container>
                                <Carousel.Caption>
                                    <div className='scrollDiv'>
                                        <a href='#height' className='text-decoration-none text-white'>scroll
                                        </a>
                                    </div>
                                    <div className='scrollDiv'>
                                        <a href='#height' className='text-decoration-none text-white'>
                                            <span className='line' ></span>
                                            <div className='arrowSpan mouse-croll'>
                                                <div className="down-arrow-1"></div>
                                                <div className="down-arrow-2"></div>
                                            </div>
                                        </a>
                                    </div>
                                </Carousel.Caption>

                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
