import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/Article/Banner';
import BlogDetail from '../Components/Article/BlogDetail';

export default function Articles() {
 
  return (
    <main>
      <HelmetProvider>
      <Helmet>
                <title>Blogs | Laya Developers</title>
            </Helmet>
            </HelmetProvider>
      <Banner />
      <BlogDetail />
    </main>
  )
}
