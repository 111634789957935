import React, { useState } from 'react'
import { Col, Container, Row, Form , Button } from 'react-bootstrap'
import career1 from "../../Assets/Images/career1.webp"
import {omit} from 'lodash'
import axios from "axios";
export default function Join() {
    const [formVal, setFormVal] = useState({name:'', phone:'', email:'',resume:null, subject:'', message:''})
    const [response, setresponse] = useState()
    const [disabled, setdisabled] = useState('')
    const [errors, setErrors] = useState()
    const validate = (event, name, value) => {
        //A function to validate each input values

        switch (name) {
           
        
            case 'email':
                if(
                    !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ){
                    setErrors({
                        ...errors,
                        email:'Enter a valid email address'
                    })
                }else{

                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                    
                }
            break;
            
            case 'phone':
                if(
                    !new RegExp(/[0-9+]/g).test(value)
                ){
                    setErrors({
                        ...errors,
                        phone:'Enter Valid Phone Number'
                    })
                }else{

                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                    
                }
            break;
            
            default:
                break;
        }
    }
    
    //A method to handle form inputs
    const handleChange = (event) => {
        //To stop default events    
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        setFormVal({
            ...formVal,
            [name]:val,
        })
        validate(event,name,val);

        //Let's set these values in state
       

    }
    const handleFileChange = (event) => {
        //To stop default events    
        event.persist();
        let name = event.target.name;
        let val = event.target.files[0];
       
        setFormVal({
            ...formVal,
            [name]:val,
        })

        //Let's set these values in state
       

    }
      const handlecareerSubmit = (e) => {
        e.preventDefault()
        setdisabled("disabled");
        var formData = new FormData();
        formData.append('files',formVal.resume,formVal.resume.name);
        formData.append('name', formVal.name);
        formData.append('phone', formVal.phone);
        formData.append('email', formVal.email);
        formData.append('subject', formVal.subject);
        formData.append('message', formVal.message);
        //console.log(formVal.resume.name);
        
        axios.post(`https://backend.layadevelopers.com/api/careerAdd`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },

        })
          .then(res => {
            //clearForm();
            //console.log(res.data);
            setresponse(res.data);
            
            setTimeout(() =>
                window.location.reload()
            , 2000);
          })
          .catch(errors => {
            setresponse(errors);
        });
    
    }
  return (
    <section className='py-5' id='join'>
    <div className=' aboutSec'>
        <div className="containerbackground">
            <h1>LAYA</h1>
        </div>
    <Container>
        <Row>
            <Col xs={12} md={12} lg={12}>
                <Row>
                    <Col xs={12} md={6} lg={6} className="my-auto">
                        <div className='heading-all text-uppercase text-start mb-4' data-aos="fade-right" data-aos-duration="1500">
                            <h2>Become An Agent</h2>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <p className='text-para'>Want to be part of a rapidly growing real estate developer in Dubai? Get in touch with us today!</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="my-auto">
                        <div className='p-relative mx-0 mx-md-3 mx-lg-5'>
                            <div className='boxPrimary' data-aos="fade-left" data-aos-duration="1500"></div>
                            <img src={career1}  data-aos="fade-left" data-aos-duration="2000" className="img-fluid p-4" alt="Laya Developers" />
                        </div>
                    </Col>
                </Row>
                <Row className='justify-content-center mt-3'>
                    
                    <Col xs={12} md={8} lg={7} className="my-auto">
                        <div className='p-5 bgMain'>
                            <div className='heading-all text-white text-uppercase text-start' data-aos="fade-up" data-aos-duration="1500">
                                <h2>Drop your cv here</h2>
                            </div>
                            <Form   onSubmit={handlecareerSubmit} className='footForm' encType="multipart/form-data"  data-aos="fade-up" data-aos-duration="2000">
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridName">
                                        <Form.Control onChange={handleChange} value={formVal.name} type="text" name="name" placeholder="Your Name *" required />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPhone">
                                        <Form.Control onChange={handleChange} value={formVal.phone} type="text" name="phone" placeholder="Phone Number *" required />
                                        { errors && <span>{errors.phone}</span>  }
                                    </Form.Group>
                                </Row>
                                

                                <Form.Group className="mb-3" controlId="formGridEmail">
                                    <Form.Control onChange={handleChange} value={formVal.email} type="email" name="email" placeholder="Your Email *" required />
                                    { errors && <span>{errors.email}</span>  }
                                </Form.Group>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridNme">
                                        <Form.Control type="file"  onChange={handleFileChange} name="resume" placeholder="Resume *" required />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3" controlId="formGridEmail">
                                    <Form.Control onChange={handleChange} value={formVal.subject} type="text" name="subject"  placeholder="Subject *" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGridMessage">
                                    <Form.Control as="textarea" onChange={handleChange} value={formVal.message} name="message" rows={3} placeholder="Message" />
                                </Form.Group>


                                <div>
                                    <Button variant="primary" type="submit" className='btnMain btnSubmit' disabled={disabled}>
                                        Submit
                                    </Button>
                                    { response && <span>{response}</span>  }
                                </div>

                            </Form>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
    </div>
</section>
  )
}

