import React, { useState } from 'react'
import { Container, Row, Col, Carousel, Form, Button } from 'react-bootstrap'
import {omit} from 'lodash'
import axios from "axios";
import banner1 from "../../Assets/Images/brandPromise.webp"
import visa1 from '../../Assets/Images/visa1.png';
import visa from '../../Assets/Images/visa.png';
import master from '../../Assets/Images/master.png';
import master1 from '../../Assets/Images/master2.png';
import magnati from '../../Assets/Images/magnati.png';
import { Link } from 'react-router-dom'
export default function Banner(){
    
    const [formVal, setFormVal] = useState({name:'', phone:'', email:'', message:''})
    const [errors, setErrors] = useState()
    const [response, setresponse] = useState()
    const [disabled, setdisabled] = useState('')
    const [ policyValue, setPolicyValue] = useState(true);
    //A method to handle form inputs
    const handleChange = (event) => {
        //To stop default events    
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        setFormVal({
            ...formVal,
            [name]:val,
        })
        validate(event,name,val);

        //Let's set these values in state
    }
    const validate = (event, name, value) => {
        //A function to validate each input values

        switch (name) {
           
            case 'email':
                if(
                    !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ){
                    setErrors({
                        ...errors,
                        email:'Enter a valid email address'
                    })
                }else{

                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                    
                }
            break;
            
            case 'phone':
                if(
                    !new RegExp(/[0-9+]/g).test(value)
                ){
                    setErrors({
                        ...errors,
                        phone:'Enter Valid Phone Number'
                    })
                }else{
                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                }
            break;
            
            default:
                break;
        }
    }

    const handlePaySubmit = (e) => {
        
        e.preventDefault()
        setdisabled("disabled");
        
        const formData = {
            name: formVal.name,
            phone: formVal.phone,
            email: formVal.email,
            amount: formVal.amount,
            apartmentNumber: formVal.apartmentNumber,
            project: formVal.project,
            policyValue: formVal.policyValue
          };
          axios.post(`https://backend.layadevelopers.com/api/payAmount`, {formData},{
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
            //   clearForm();
            window.location.href = res.data['data'];
            // console.log(res.data['data']);
            //  setresponse(res.data);
              
            //   setTimeout(() =>
            //       window.location.reload()
            //   , 2000);
            })
            .catch(errors => {
                console.log(errors);
              setresponse(errors);
          });
        // var formData = new FormData();
        // formData.append('name', formVal.name);
        // formData.append('phone', formVal.phone);
        // formData.append('email', formVal.email);
        // formData.append('amount', formVal.amount);
        // formData.append('apartment', formVal.apartmentNumber);
        // formData.append('project', formVal.project);
        // formData.append('policy', policyValue);
        // console.log(formData);
        // axios.post(`https://backend.layadevelopers.com/api/payAmount`, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },


        // })
        //   .then(res => {
        //     //clearForm();
        //     alert(res.data);
        //     setresponse(res.data);
            
        //     setTimeout(() =>
        //         window.location.reload()
        //     , 2000);
        //   })
        //   .catch(errors => {
        //     setresponse(errors);
        // });
        
        
        // {
        //     name: formVal.name,
        //     phone: formVal.phone,
        //     email: formVal.email,
        //     amount: formVal.amount,
        //     apartmentNumber: formVal.apartmentNumber,
        //     project: formVal.project,
        //     termCondition: policyValue
        //   })
        //   .then(res => {
            
        //     console.log(res.data.Transaction.PaymentPortal)
        //     window.location.replace(res.data.Transaction.PaymentPortal);
        //     //clearForm();
        //     //alert(res.data);
        //     setresponse(res.data);
            
            
        //   })
        //   .catch(errors => {
        //     console.log(errors)
        //     setresponse(errors);
        // });
    
    }

        return (
            <section>
                <Container fluid className='px-0'>
                    <Row className='g-0'>
                        <Col xs={12} md={12} lg={12}>
                            <Carousel className='bannerCarousel'>
                                <Carousel.Item interval={3000}>
                                    <Container className='my-5' style={{paddingTop: "125px"}}>
                                        <Row className='justify-content-center'>
                                            <Col xs={12} md={6} lg={6} data-aos="fade-right" data-aos-duration="2000" style={{
                                                background: "url(" + banner1 + ")", height: "500", backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                width: "100",
                                                display: "flex", justifyContent: "end", flexDirection: "column"
                                            }}>
                                                <div className='p-3 text-center'>
                                                    <h3 className='text-black text-uppercase'>Pay WITH CARD</h3>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className='footSocLogo pe-3'><img src={visa} className="img-fluid" width="100" alt="logo" /></div>
                                                        <div className='footSocLogo pe-3'><img src={visa1} className="img-fluid" width="100" alt="logo" /></div>
                                                        <div className='footSocLogo pe-3'><img src={master} className="img-fluid" width="100" alt="logo" /></div>
                                                        <div className='footSocLogo pe-3'><img src={master1} className="img-fluid" width="100" alt="logo" /></div>
                                                        <div className='footSocLogo'><img src={magnati} className="img-fluid" width="100" alt="logo" /></div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} lg={6}>
                                                <div className='p-5 p-md-3 p-lg-5 bg-white shadow'>
                                                    <div >
                                                        <h3 className='text-main text-uppercase'>Pay Now</h3>
                                                    </div>
                                                    <Form className='payForm' onSubmit={handlePaySubmit}>
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} controlId="formGridNme">
                                                            <Form.Control onChange={handleChange} value={formVal.name} type="text" name="name" placeholder="Your Name *"  required />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} controlId="formGridPhone">
                                                            <Form.Control  onChange={handleChange} value={formVal.phone} type="text" name="phone" placeholder="Phone Number *" required />
                                                                { errors && <span>{errors.phone}</span>  }
                                                            </Form.Group>
                                                        </Row>

                                                        <Form.Group className="mb-3" controlId="formGridEmail">
                                                        <Form.Control  onChange={handleChange} value={formVal.email} type="email" name="email" placeholder="Your Email *" required />
                                                            { errors && <span>{errors.email}</span>  }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formGridEmail">
                                                            <Form.Control onChange={handleChange} value={formVal.amount} type="number" name="amount" placeholder="AED *" required />
                                                        </Form.Group>
                                                        <Form.Select className="mb-3" aria-label="Default select example" onChange={handleChange} name="project" required>
                                                            <option>Select Project</option>
                                                            <option value="Laya Heights">Laya Heights</option>
                                                            <option value="Laya Residences">Laya Residences</option>
                                                            <option value="Laya Mansion">Laya Mansion</option>
                                                            <option value="Laya Courtyard">Laya Courtyard</option>
                                                        </Form.Select>
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} controlId="formGridNme">
                                                                <Form.Control  onChange={handleChange} name="apartmentNumber" value={formVal.apartmentNumber} type="text" placeholder="Apartment number *" required />
                                                            </Form.Group>
                                                        </Row>

                                                        <Form.Group className="mb-3 d-block d-lg-flex d-md-flex" id="formGridCheckbox">
                                                            <Form.Check type={"checkbox"}>
                                                                <Form.Check.Input
                                                                type={"checkbox"}
                                                                defaultChecked={true}
                                                                onClick={(e) => {
                                                                    setPolicyValue(e.target.checked)
                                                                }}
                                                                />
                                                                <Form.Check.Label>I accept the&nbsp;<Link to={"/payment-terms"} className='text-decoration-none text-main'> terms and conditions </Link></Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>

                                                        {/* <Form.Group className="mb-3 d-block d-lg-flex d-md-flex" id="formGridCheckbox">
                                                            <Form.Check name="termCondition" onChange={handleChange} value={formVal.termCondition} type="checkbox" label="I accept the" /> &nbsp;<Link to={"/payment-terms"} className='text-decoration-none text-main'> terms and conditions </Link>
                                                        </Form.Group> */}
                                                        <div>
                                                            <Button variant="primary" type="submit" className='btnMain' >
                                                            Pay Now
                                                            </Button>
                                                            { response && <span>{response}</span>  }
                                                        </div>

                                                    </Form>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    
}
