import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default class About extends Component {
    render() {
        return (
            <section className='py-5' id='home'>
                <Container>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <h1>TERMS AND CONDITIONS</h1>
                                    <h2>1. AGREEMENT AND AMENDMENTS</h2>
                                    <p>1.1 By accessing any part of the Website, you (“You” or “Your”) agree to the Terms.</p>
                                    <p>1.2 The Company reserves the right to update the Terms from time to time. If You do not
                                        wish to accept the new Terms You should not continue to use the Website. Your use of the
                                        Website indicates Your agreement to be bound by the new Terms.</p>
                                    <p>1.3 Certain provisions of the Terms may be superseded by expressly designated legal
                                        notices or terms located on particular pages on the Website.</p>
                                    <p>1.4 Additional terms and conditions which vary or supplement the Terms may apply. If You
                                        apply for any property, product or service detailed on the Website, these terms and
                                        conditions should be read in conjunction with any other terms and conditions which relate
                                        to any such property, product or service and, in the event of any contradiction between the
                                        Terms and the specific terms and conditions relating to such property, product or service,
                                        the latter shall prevail.</p>
                                    <p>1.5 You agree to use the Website (or any part of it) in accordance with all applicable laws
                                        relevant to the jurisdiction from which You access the Website.</p>

                                    <h2>2. INTELLECTUAL PROPERTY</h2>

                                    <p>
                                        2.1 Copyright, trademark, database and other intellectual property rights protect the
                                        content of this Website and You acknowledge that the material and content (including
                                        without limitation photographs and graphical images) supplied as part of this Website shall
                                        remain with the Company or its licensors.</p>
                                    <p>2.2 You are permitted to print and download extracts from the Website only for Your own
                                        use, and even then only on the following basis:</p>
                                    <ul>
                                        <li>no documents or related graphics on the Website are modified in any way;</li>
                                        <li>no graphics on the Website are used separately from the corresponding text; and</li>
                                        <li>You keep intact all copyright and proprietary notices.</li>
                                    </ul>
                                    <p>For the avoidance of doubt, You may not use any part of the materials and/or content on
                                        the Website for commercial purposes without obtaining a licencse to do so from the
                                        Company or the Company’s licensors.</p>
                                    <p>2.3 If You print, copy or download any part of the Website in breach of these Terms, Your
                                        right to use the Website will cease immediately and You must, at the Company’s option,
                                        return or destroy any copies of the materials You have made.</p>
                                    <p>2.4 Any unauthorised downloading, re-transmission or other copying or modification of any
                                        of the contents of the Website may be in breach of statutory or common law rights which
                                        could be the subject of legal action.</p>
                                    <p>2.5 Any use of extracts from the Website for any purpose, other than in accordance with
                                        clause 2.1, is prohibited.</p>
                                    <p>2.6 Subject to clause 2.1, no part of the Website may be reproduced or stored in any other
                                        website or included in any public or private electronic retrieval system or service without
                                        the Company’s prior written permission.</p>
                                    <p>2.7 Any rights not expressly granted in the Terms are reserved.</p>

                                    <h2>3. SERVICE ACCESS</h2>

                                    <p>3.1 Access to the Website is permitted on a temporary basis and the Company reserves
                                        the right to withdraw or amend the Website without notice. The Company will not be liable
                                        if for any reason the Website is unavailable at any time or for any period.</p>
                                    <p>3.2 You are responsible for making all arrangements necessary for You to have access to
                                        the Website. You are also fully responsible for ensuring that any person who has access to
                                        the Website through Your internet connection is aware of these Terms, and that they
                                        comply with them.</p>
                                    <p>3.3 The Company may at its sole discretion and for any reason restrict access without
                                        notice to some parts of the Website or to the entire Website, including without limitation to
                                        as users who have registered with the Company.</p>
                                    <p>3.4 The information on the Website is provided purely for information and does not
                                        constitute or form part of any offer or invitation (including without limitation an offer to sell,
                                        lease or issue). All properties, products and services are subject to availability and the
                                        Company gives no guarantee in this regard.</p>


                                    <h2>4. VISITOR MATERIAL AND CONDUCT</h2>

                                    <p>4.1 Other than personally identifiable information covered under the Company’s Privacy
                                        Policy set out in clause 5, below, any ideas, suggestions, comments, reviews, concepts,
                                        know-how, information, techniques, text, photos, images, sounds, graphics or other
                                        content (altogether the “Materials”) You transmit or post to the Website shall be
                                        considered non-confidential and non-proprietary. The Company shall have no obligations
                                        with respect to such Materials.</p>
                                    <p>4.2 By submitting Materials to the Company via the Website, You grant the Company, its
                                        subsidiaries, its parents and all other companies in the group of companies of which the
                                        Company forms part an unrestricted, world-wide, royalty-free, irrevocable licence to use
                                        the Materials at the Company’s own discretion for any purpose including, without limitation,
                                        use, reproduce, display, adapt, modify, transmit, disclose, distribute to third parties and
                                        commercially exploit the Materials in any medium together with an unrestricted right to use
                                        any ideas, concepts, know-how or techniques contained in the Materials for any purpose.
                                        You also, save as permitted by law, waive any moral rights in the same.</p>
                                    <p>4.3 The Company reserves the right to monitor, inspect and remove (without liability to
                                        You) any Materials or posting You make on the Website, if, in absolute the Company’s
                                        opinion, such material does not comply with the content standards set out in the
                                        Company’s acceptable use policy or is in breach of these Terms. The Company also has
                                        the right to disclose Your identity to any third party who is claiming that any Materials
                                        posted or uploaded by You to the Website is an infringement of their intellectual property
                                        rights or of their right to privacy.</p>
                                    <p>4.4 The Company will not be responsible, or liable to any third party, for the content or
                                        accuracy of any Materials posted by You or any other user of the Website.</p>
                                    <p>4.5 You warrant and agree that in using the Website (or any part thereof) You will not:</p>
                                    <ul>
                                        <li>use the Website in any way that may lead to the encouragement, procurement or
                                            carrying out of any criminal or unlawful activity;</li>
                                        <li>transfer files to or through the Website that contain viruses, Trojans, worms or
                                            anything else that is malicious or technologically harmful or that may interfere with,
                                            interrupt or disrupt the normal operation of the Website;</li>
                                        <li>introduce or transfer through the Website material that is defamatory, libellous,
                                            obscene, liable to incite racial hatred, offensive, threatening, discriminatory,
                                            menacing or blasphemous, or material that may cause annoyance, inconvenience
                                            or needless anxiety;</li>
                                        <li>use the Website in a way to cause it to be interrupted, damaged, rendered less
                                            efficient or in a way so that the effectiveness or functionality of the Website is
                                            impaired;</li>
                                        <li>use the Website in a manner that infringes the rights of another person, firm or
                                            company (including, without limitation, intellectual property rights, confidentiality
                                            rights or rights of privacy);</li>
                                        <li>post or transmit to or from the Website any material for which You have not
                                            obtained all necessary licences and/or approvals;</li>
                                        <li>do anything that would seek to bypass or interfere with any security features of the
                                            Website, or interfere with any of the Company’s websites, servers, or networks;</li>
                                        <li>attempt to gain unauthorised access to any part or component of the Website, the
                                            server on which the Website is stored or any server, computer or database
                                            connected to the Website;</li>
                                        <li>upload files which contain an active hypertext link to another website;</li>
                                        <li>impersonate any person or entity, including without limitation any of the Company’s
                                            employees, associates, principals or directors nor misrepresent or falsely state Your
                                            affiliation with any person or entity</li>
                                        <li>offer to sell, advertise, promote or solicit any goods or services for any commercial
                                            purpose, or otherwise engage in any commercial activity; or</li>
                                        <li>do anything which may constitute or encourage conduct that could be considered a
                                            criminal offence, give rise to civil liability, or otherwise be contrary to the law of or
                                            infringe the rights of any third party, in any country of the world.</li>
                                    </ul>
                                    <p>4.6 You may not misuse the Website (including, without limitation, by hacking).</p>
                                    <p>4.7 The Company may fully co-operate with any law enforcement authorities or court order
                                        requesting or directing the Company to disclose the identity or locate anyone posting any
                                        Material in breach of the Terms or otherwise for the prevention or detection of crime or the
                                        apprehension or prosecution of offenders. There may be other circumstances in which the
                                        Company may be required by law to disclose information about You or Your use of the
                                        Website</p>
                                    <p>4.8 In the event that You have a claim against another user of the Website arising out of
                                        that user’s use of the Website, You agree to pursue such right, claim or action
                                        independently of and without recourse to Us.</p>
                                    <h2>5. PRIVACY POLICY</h2>
                                    <p>5.1 You acknowledge and accept that the Company’s web server automatically collects
                                        Your IP address when You visit the Website and that the Company may use Your IP
                                        address to help diagnose problems with the Company’s server and to compile statistics on
                                        Website usage.</p>
                                    <p>5.2 The Company will treat Your personal information as confidential and will only disclose
                                        it for the following purposes:</p>
                                    <ul>
                                        <li>if the Company believes it is necessary to comply with a requirement of the UAE
                                            law enforcement agencies</li>
                                        <li>processing orders (if applicable)</li>
                                        <li>where the personal information is given to the Company for that purpose, evaluating
                                            that personal information given against present or future employment opportunities
                                            within the Company or any of its affiliated companies</li>
                                        <li>to administer this Website</li>
                                        <li>for statistical purposes to improve this Website and its services to You</li>
                                        <li>to notify You of products, special offers or competitions that may be of interest to
                                            You; or</li>
                                        <li>to disclose to affiliated companies and other relevant third parties, who may contact
                                            You about their products or services that may be of interest to You.</li>
                                    </ul>
                                    <h2>6. THIRD PARTY LINKS</h2>
                                    <p>6.1 Links to third party websites on the Website are provided solely for Your convenience.
                                        If You use these links, You leave the Website. The Company has not reviewed all of these
                                        third party websites. The Company does not control and is not responsible for these third
                                        party websites or their content or availability. The Company therefore does not endorse or
                                        make any representations about any of the third party websites, or any material found
                                        there, or any results that may be obtained from using them. If You decide to access any of
                                        the third party websites linked to the Website, You do so entirely at Your own risk.</p>
                                    <p>6.2 If You would like to link to the Website, You may only do so on the basis that You link
                                        to, but do not replicate, the home page of the Website, and subject to the following
                                        conditions:</p>
                                    <ul>
                                        <li>You do not remove, distort or otherwise alter the size or appearance of any of the
                                            Logos of any of the Group (including without limitation the Akshara Global Real
                                            Estate Development logo;)</li>
                                        <li>You do not create a frame or any other browser or border environment around the
                                            Website;</li>
                                        <li>You do not in any way imply that the Company is endorsing any products or
                                            services other than its own;</li>
                                        <li>You do not misrepresent Your relationship with the Company or present any other
                                            false information about the Company;</li>
                                        <li>You do not otherwise use any of the trademarks displayed on the Website without
                                            express written permission from the Company;</li>
                                        <li>You do not link from a website that is not owned by You; and</li>
                                        <li>Your website does not contain content that is distasteful, offensive or controversial,
                                            infringes any intellectual property rights or rights of any other person or otherwise
                                            does not comply with all applicable laws and regulations.</li>
                                    </ul>
                                    <p>6.3 The Company expressly reserves the right to revoke the right granted in clause 6.2 for
                                        breach of these Terms and to take any action it deems appropriate.</p>
                                    <p>6.4 You shall fully indemnify the Company for any loss or damage whatsoever suffered by
                                        the Group as a result of breach of clause 6.2.</p>
                                    <h2>7. DISCLAIMER</h2>
                                    <p>7.1 While the Company endeavours to ensure that the information on the Website is
                                        correct, the Company neither warrants nor represents the accuracy, verification or
                                        completeness of any of the material on the Website. The Company may make changes to
                                        the material on the Website, or to any of the properties, products and prices described in it,
                                        at any time without notice. The material on the Website may be out of date and the
                                        Company makes no commitment to update such material. The Company does not accept
                                        any liability for any errors or omissions in respect of such material. If You are in any doubt
                                        as to the validity of such material, please contact the Company.</p>
                                    <p>7.2The material on the Website is provided ‘as is’, without any representations, conditions,
                                        warranties or other terms of any kind. Without prejudice to the generality of the foregoing,
                                        the Company makes no representation or warranty as to the currency, adequacy or
                                        suitability, and shall accept no liability for the accuracy or completeness of the information
                                        contained in this website or for any reliance placed by any person on the information. To
                                        the maximum extent permitted by law, the Company provides You with the Website on the
                                        basis that the Company excludes all representations, warranties, conditions and other
                                        terms (including, without limitation, the conditions implied by the laws of the United Arab
                                        Emirates and the Emirate of Dubai) which, but for these Terms, might have effect in
                                        relation to the Website.</p>
                                    <p>7.3 The Company disclaims all liability and responsibility arising from any reliance placed
                                        on the material on this Website by You or any visitor to the Website, or by anyone who
                                        may be informed of any of its contents. Any decisions or action taken by You on the basis
                                        of information provided on or via the Website are at Your sole discretion and risk and You
                                        should obtain professional advice where necessary.</p>
                                    <p>7.4 While we endeavour to ensure that the information on this website is correct, the
                                        Company neither warrants nor represents the accuracy, verification or completeness of
                                        any of this material. We may make changes to the material on this website, or to any of the
                                        properties or products described in it, at any time without notice. Please note that any
                                        advertising or promotional material is indicative only. The material maybe out of date and
                                        the Company makes no commitment to update such material. We do not accept any
                                        liability for any errors or omissions in respect of such material. Without prejudice to the
                                        generality of the foregoing, the Company makes no representation or warranty as to the
                                        currency, adequacy or suitability, and shall accept no liability for the accuracy or
                                        completeness of the information contained in this website or for any reliance placed by any
                                        person on the information. The Company disclaims all liability and responsibility arising
                                        from any reliance placed on the material in this website or on any decisions or actions
                                        taken on the basis of the information, by you or any visitor, or by anyone who may be
                                        informed of the site content.</p>
                                    <h2>8. INDEMNITY AND LIABILITY</h2>
                                    <p>8.1 You agree to indemnify the Company, any other party (whether or not involved in
                                        creating, producing, maintaining or delivering the Website), and any company in the group
                                        of which the Company forms part, its predecessors, successors and affiliates, the present
                                        and former owners, partners, shareholders, directors, officers, employees, agents and
                                        representatives of any of the foregoing and their respective successors and assigns
                                        (collectively, the "Group") against all claims, liability, damages, losses, costs and
                                        expenses, (including legal fees), suffered by the Group and arising out of any breach of
                                        the Terms by You (including in relation to any Materials submitted by You) or any other
                                        liabilities arising out of Your use of the Website or the use by any other person accessing
                                        the Website using Your internet account.
                                    </p><p>8.2 The Group, exclude all liability and responsibility for any amount or kind of loss or
                                        damage that may result to You or a third party (including without limitation, any direct,
                                        indirect, punitive or consequential loss or damages, or any loss of income, profits,
                                        goodwill, data, contracts, use of money, or loss or damages arising from or connected in
                                        any way to business interruption, and whether in tort (including without limitation
                                        negligence), contract or otherwise, in connection with the Website in any way or in
                                        connection with the use, inability to use or the results of use of the Website, any websites
                                        linked to the Website or the material on such websites, including but not limited to loss or
                                        damage due to viruses that may infect Your computer equipment, software, data or other
                                        property on account of Your access to, use of, or browsing the Website or Your
                                        downloading of any material from the Website or any websites linked to the Website.
                                    </p><p>8.3 If Your use of material on the Website results in the need for servicing, repair or
                                        correction of equipment, software or data, You assume all costs thereof.</p>
                                    <h2 className='text-uppercase'>9. GOVERNING LAW AND JURISDICTION</h2>
                                    <p>9.1 These Terms shall be governed by and construed in accordance with the laws of the
                                        United Arab Emirates and the Emirate of Dubai. Disputes arising in connection with these
                                        Terms shall be subject to the exclusive jurisdiction of the courts of the Emirate of Dubai.</p>
                                    <p>9.2 If any of the Terms should be determined to be illegal, invalid or otherwise</p>
                                    <h2 className='text-uppercase'>10. Delivery/Shipping/Services Policy:</h2>
                                    <ul>
                                        <li>(Website) will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE</li>
                                    </ul>
                                    <h2 className='text-uppercase'>11. Refund/Return Policy</h2>
                                    <ul>
                                        <li>Refunds, if applicable, at the discretion of Laya Developers, will be done only
                                            through the Original Mode of Payment</li>
                                        <li>Refunds of any payments (if any) made through the Website are subject to the
                                            terms and conditions of the Booking Confirmation or Sale and Purchase Agreement.</li>
                                    </ul>
                                    <h2>12. Cancellation & Replacement Policy:</h2>
                                    <ul>
                                        <li>Each Merchant/service provider has its own refund and cancellation policy, as a result of which we adhere to the same. Also, refunds will be done only through the Original Mode of Payment.</li>
                                    </ul>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
