import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../Components/PayComplete/Banner'

export default function PayComplete() {
  
  return (
    <main>
      <HelmetProvider><Helmet><title>Pay Now | Laya Developers</title></Helmet></HelmetProvider>
      <Banner />
    </main>
  )
}
