import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import About from '../Components/Brand/About'
import Animation from '../Components/Brand/Animation'
import Banner from '../Components/Brand/Banner'
import Community from '../Components/Brand/Community'
import Journey from '../Components/Brand/Journey'
import Why from '../Components/Brand/Why'

export default function Brand() {
  
  return (
    <main>
      <HelmetProvider>
      <Helmet>
                <title>Our Brand | Laya Developers</title>
      </Helmet>
      </HelmetProvider>
        <Banner />
        <About />
        <Animation />
        <Why />
        <Journey />
        
        <Community />
        
    </main>
  )
}
